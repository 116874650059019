import moment from 'moment'

const initialState = {
    devices: [],
    properties: [],
    usage: [],
    begin: moment(),
    end: moment(),
    loaded: false
}

const apartmentDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_APARTMENT_DASHBOARD':
            const property = action.data.apartment.property_id
            property.apartments = [action.data.apartment]

            return {
                ...state,
                devices: action.data.apartment.devices,
                properties: [property],
                usage: action.data.usage,
                begin: action.begin,
                end: action.end,
                loaded: true
            }
        case 'CLEAR_APARTMENT_DASHBOARD':
            return initialState
        default:
            return { ...state }
    }
}
export default apartmentDashboardReducer