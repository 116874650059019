// ** React Imports
import { createContext, useEffect, useState } from 'react'

// ** Create Context
const ThemeColors = createContext()

const ThemeContext = ({ children }) => {
    // ** State
    const [colors, setColors] = useState({})

    //** ComponentDidMount
    useEffect(() => {
        if (window !== 'undefined') {
            //** Get variable value
            const getHex = color => window.getComputedStyle(document.body).getPropertyValue(color).trim()

            //** Colors obj
            const obj = {
                primary: {
                    light: getHex('--primary').concat('aa'),
                    main: getHex('--primary')
                },
                secondary: {
                    light: getHex('--secondary').concat('aa'),
                    main: getHex('--secondary')
                },
                success: {
                    light: getHex('--success').concat('aa'),
                    main: getHex('--success')
                },
                danger: {
                    light: getHex('--danger').concat('aa'),
                    main: getHex('--danger')
                },
                warning: {
                    light: getHex('--warning').concat('aa'),
                    main: getHex('--warning')
                },
                info: {
                    light: getHex('--info').concat('aa'),
                    main: getHex('--info')
                },
                dark: {
                    light: getHex('--dark').concat('aa'),
                    main: getHex('--dark')
                }
            }

            setColors({ ...obj })
        }
    }, [])

    return <ThemeColors.Provider value={{ colors }}>{children}</ThemeColors.Provider>
}

export { ThemeColors, ThemeContext }