import { useContext, useEffect, useState } from 'react'
import { ThemeColors } from '../../../../utility/context/ThemeColors'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Row } from 'reactstrap'
import img_co2 from '@src/assets/images/icons/kohlendioxid.png'
import { defaultCost, heatingSystemsEmissions } from '../../../../utility/Utils'
import { hasHeatingData, hasWWaterData } from '../../../../utility/DeviceData'

const HeatingSystemsWidget = (props) => {
    const { colors } = useContext(ThemeColors)
    const [sumHeat, setSumHeat] = useState(0)
    const [sumCost, setSumCost] = useState(0)
    const [sumEmissions, setSumEmissions] = useState(0)
    const properties = useSelector(state => state[props.store].properties)
    const usage = useSelector(state => state[props.store].usage)

    useEffect(() => {
        if (usage && properties.length > 0) {
            const date = moment().set('date', 1)

            if (hasHeatingData(usage)) {
                const heatDifference = usage.heating_data

                let heat = heatDifference[date.toDate().toDateString()]
                if (heat) {
                    if (hasWWaterData(usage)) {
                        const warmWaterDifference = usage.wwater_data
                        const warmWaterEnergy = (warmWaterDifference[date.toDate().toDateString()] ?? 0.0) * 2.5 * (properties[0].warm_water_temperature - 10)
                        heat += warmWaterEnergy
                    }
                    setSumHeat(heat)

                    const emissions = heat * heatingSystemsEmissions[properties[0].heating]
                    if (emissions) {
                        setSumEmissions(emissions)
                    }

                    let cost = heat * defaultCost[properties[0].heating]
                    if (properties[0].energy_cost?.length > 0) {
                        const now = moment().subtract(1, 'month').startOf('month')
                        properties[0].energy_cost.map((energy_cost) => {
                            if (moment(energy_cost.begin).isSameOrBefore(now) && (moment(energy_cost.end).isAfter(now) || !energy_cost.end)) {
                                cost = heat * energy_cost.cost
                            }
                        })
                    }

                    if (cost) {
                        setSumCost(cost)
                    }
                }
            }
        }
    }, [properties])

    return (
        <>
            <h4>Verbrauchsübersicht</h4>
            <Row className={'widget-body align-items-center'}>
                <div
                    className={`col mb-1 mb-md-0 d-flex flex-column align-items-center py-1`}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                        <path fill={colors.primary.main}
                              d="M11.65 42q-2.35 0-4-1.625T6 36.4V16h3v20.4q0 1.1.775 1.85t1.875.75q1.1 0 1.85-.75t.75-1.85V11.65q0-2.35 1.65-4t4-1.65q2.35 0 3.975 1.65t1.625 4V36.4q0 1.1.775 1.85t1.875.75q1.1 0 1.85-.75t.75-1.85V11.65q0-2.35 1.65-4t4-1.65q2.35 0 3.975 1.65t1.625 4V32h-3V11.65q0-1.1-.75-1.875Q37.5 9 36.4 9t-1.875.775q-.775.775-.775 1.875V36.4q0 2.35-1.625 3.975T28.15 42q-2.35 0-4-1.625T22.5 36.4V11.65q0-1.1-.75-1.875Q21 9 19.9 9t-1.875.775q-.775.775-.775 1.875V36.4q0 2.35-1.625 3.975T11.65 42Z"/>
                    </svg>
                    <h5 className={'mb-0'}>
                        <span className={'font-large-1'}>
                            {sumHeat.toLocaleString('de-DE', { maximumFractionDigits: 0 })}
                        </span>
                        <b>&nbsp;kWh</b>
                    </h5>
                    <p className={'font-medium-1 mb-0'}>Wärmeenergie</p>

                </div>
                <div
                    className={`col mb-1 mb-md-0 d-flex flex-column align-items-center py-1`}>
                    <span className={'font-large-2'} style={{ color: colors.primary.main, lineHeight: '48px' }}>€</span>
                    <h5 className={'mb-0'}>
                        <span className={'font-large-1'}>
                          {sumCost.toLocaleString('de-DE', { maximumFractionDigits: 0 })}
                        </span>
                        <b>&nbsp;€</b>
                        {properties && properties[0]?.heating === 'remote' &&
                            <a title="Die Emissionen von Nahwärme und Fernwärme können in der Realität deutlich nach oben oder unten abweichen, je nachdem wie Ihr Erzeugerpark aufgestellt ist"
                               style={{
                                   display: 'inline-block',
                                   borderRadius: '50%',
                                   border: '1px solid #6e6b7b',
                                   lineHeight: '1.25rem',
                                   textAlign: 'center',
                                   fontSize: '.85rem',
                                   marginLeft: '5px',
                                   height: '20px',
                                   width: '20px'
                               }}>i</a>
                        }
                    </h5>
                    <p className={'font-medium-1 mb-0'}>Heizkosten</p>
                </div>
                <div
                    className={`col mb-1 mb-md-0 d-flex flex-column align-items-center py-1`}>
                    <img src={img_co2} width="48"/>
                    <h5 className={'mb-0'}>
                        <span className={'font-large-1'}>
                          {sumEmissions.toLocaleString('de-DE', { maximumFractionDigits: 0 })}
                        </span>
                        <b>&nbsp;kg</b>
                        {properties && properties[0]?.heating === 'heat_pump' &&
                            <a title="Bei der Berechnung der CO2 Emissionen für Wärmepumpen wird von 100% erneuerbarem Strom ausgegangen"
                               style={{
                                   display: 'inline-block',
                                   borderRadius: '50%',
                                   border: '1px solid #6e6b7b',
                                   lineHeight: '1.25rem',
                                   textAlign: 'center',
                                   fontSize: '.85rem',
                                   marginLeft: '5px',
                                   height: '20px',
                                   width: '20px'
                               }}>i</a>
                        }
                    </h5>
                    <p className={'font-medium-1 mb-0'}>Co2</p>

                </div>
            </Row>
        </>
    )
}

export default HeatingSystemsWidget