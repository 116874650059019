import { toast } from 'react-toastify'

const ToastText = ({ title, body }) => {

    return (
        <>
            {title &&
                <div className="toastify-header">
                    <div className="title-wrapper">
                        <h6 className="toast-title font-weight-bold">
                            {title}
                        </h6>
                    </div>
                </div>
            }
            {body &&
                <div className="toastify-body">
          <span className="font-small-3">
              {body}
          </span>
                </div>
            }
        </>
    )
}

class ToastCtlClass {
    error(body, title = null, id = null) {
        toast.error(<ToastText title={title ?? 'Fehler'} body={body}/>, {
            autoClose: 7500,
            toastId: id ?? 'error'
        })
    }

    success(body, title = null, id = null) {
        toast.success(<ToastText title={title ?? 'Erfolg'} body={body}/>, {
            autoClose: 7500,
            toastId: id ?? 'success'
        })
    }
}

const ToastCtl = new ToastCtlClass()

export { ToastCtl }