import moment from 'moment'

const initialState = {
    devices: [],
    properties: [],
    usage: [],
    begin: moment(),
    end: moment(),
    loaded: false
}

const defaultDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_DEFAULT_DASHBOARD':
            return {
                ...state,
                devices: action.data.properties.map((property) => property.devices).flat(),
                properties: action.data.properties,
                usage: action.data.usage,
                begin: action.begin,
                end: action.end,
                loaded: true
            }
        case 'RESET_DEFAULT_DASHBOARD':
            return initialState
        default:
            return { ...state }
    }
}
export default defaultDashboardReducer