// ** Checks if an object is empty (returns boolean)
import moment from 'moment'
import useJwt from '@src/auth/jwt/useJwt'
import { filterDevices, monthlyDeviceDifference } from './DeviceData'
import EnergySumWidget from '../views/components/custom/widgets/energy-sum'
import WelcomeWidget from '../views/components/custom/widgets/welcome'
import LastMonthWaterWidget from '../views/components/custom/widgets/last-month-water'
import WaterConsumptionWidget from '../views/components/custom/widgets/water-consumption'
import LastMonthWidget from '../views/components/custom/widgets/last-month'
import BillingWidget from '../views/components/custom/widgets/billing'
import EnergyUsageWidget from '../views/components/custom/widgets/energy-usage'
import EfficiencyWidget from '../views/components/custom/widgets/efficiency'
import CostForecastWidget from '../views/components/custom/widgets/cost-forecast'
import HeatingEnergyWidget from '../views/components/custom/widgets/heating-energy'
import HeatingSystemsWidget from '../views/components/custom/widgets/heating-systems'
import EnergyForecastWidget from '../views/components/custom/widgets/energy-forecast'
import OwnerShareWidget from '../views/components/custom/widgets/owner-share'
import DataSheetWidget from '../views/components/custom/widgets/data-sheet'
import LeakageWidget from '../views/components/custom/widgets/leakage'
import ls from 'localstorage-slim'

export const generateQueryFromFilter = (query, filter) => {
    const defaultQuery = query || {}
    const search = Object.entries(filter).filter(([key, value]) => value.length > 0).map(([key, value]) => {
        if (key.indexOf('.') > -1) {
            const [parent, property] = key.split('.')
            return {
                [parent]: {
                    [property]: {
                        [property === 'id' ? '_eq' : '_icontains']: value
                    }
                }
            }
        }

        return {
            [key]: {
                _icontains: value
            }
        }
    })

    const searchQuery = {
        _and: [
            defaultQuery
        ]
    }

    if (search.length > 0) {
        searchQuery._and.push(...search)
    }

    return searchQuery
}

export const downloadFileFromFileString = (fileString, documentName) => {
    const binaryData = []
    binaryData.push(fileString)
    const fileUrl = URL.createObjectURL(new Blob(binaryData))

    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, documentName)
    } else {
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.href = fileUrl
        a.download = documentName
        a.click()
        setTimeout(() => {
            document.body.removeChild(a)
            URL.revokeObjectURL(fileUrl)
        }, 0)
    }
}

export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '')

export const isUserLoggedIn = () => {
    return localStorage.getItem('userData')
}

// ** Checks if the passed date is today
const isToday = (date) => {
    const today = new Date()
    return (
        /* eslint-disable operator-linebreak */
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
        /* eslint-enable */
    )
}

export const qmSumByProperties = (properties) => {
    let sum = 0
    const filteredProperties = properties.map((property) =>
        property.apartments.map((apartment) => apartment.size_sqm ?? 0).filter((item) => item > 0)
    )
    filteredProperties.map((apartment) => {
        apartment.map((item) => {
            sum += parseFloat(item)
        })
    })

    return sum
}

export const percentageDifference = (value, otherValue) => {
    if (otherValue === 0) {
        return 0
    }

    return (((value - otherValue) / otherValue) * 100).toFixed(0)
}

export const levy = {
    living_space: 'Wohnfläche',
    auxiliary_cost_area: 'Nebenkostenfläche',
    heating_surface: 'Heizfläche',
    hot_water_area: 'Warmwasserfläche',
    individuals: 'Personen',
    co_ownership: 'Miteigentumsanteil',
    cold_water_consumption: 'Kaltwasserverbrauch',
    own_key: 'Eigener Schlüssel'
}

export const levy_heating = {
    living_space: 'Wohnfläche',
    auxiliary_cost_area: 'Nebenkostenfläche',
    heating_surface: 'Heizfläche',
    hot_water_area: 'Warmwasserfläche',
    individuals: 'Personen',
    co_ownership: 'Miteigentumsanteil',
    cold_water_consumption: 'Kaltwasserverbrauch',
    own_key: 'Eigener Schlüssel'
}

export const levy_warm_water = {
    living_space: 'Wohnfläche',
    auxiliary_cost_area: 'Nebenkostenfläche',
    heating_surface: 'Heizfläche',
    hot_water_area: 'Warmwasserfläche',
    individuals: 'Personen',
    co_ownership: 'Miteigentumsanteil',
    cold_water_consumption: 'Kaltwasserverbrauch',
    own_key: 'Eigener Schlüssel'
}

export const heating_consumption = {
    50: '50%',
    70: '70%'
}

export const warm_water_consumption = {
    50: '50%',
    70: '70%'
}

export const tax_rate = {
    19: '19%',
    7: '7%',
    0: '0%'
}

export const fuel_tax_rate = {
    '-1': 'Anderer Mwst. Satz',
    19: '19%',
    7: '7%',
    0: '0%'
}

export const operating_current = {
    flatrate: 'Pauschal',
    non_flatrate: 'Nicht pauschal'
}

export const reduction = {
    days: 'Tage',
    cold_water_consumption: 'Kaltwasserverbrauch'
}
export const heatingSystems = {
    gas: 'Gasheizung',
    oil: 'Öl',
    oil_calorific_active: 'Öl (Brennwert aktiv)',
    pellet: 'Holzpellets',
    natural_gas: 'Erdgas',
    natural_gas_calorific_active: 'Erdgas (Brennwert aktiv)',
    remote: 'Fernwärme',
    liquid_gas: 'Flüssiggas',
    liquid_gas_calorific_active: 'Flüssiggas (Brennwert aktiv)',
    heat_pump: 'Wärmepumpe',
    coke: 'Koks',
    wood: 'Holz',
    wood_chips: 'Holzhackschnitzel',
    geothermal_heat: 'Erdwärme',
    brown_coal: 'Braunkohle',
    hard_coal: 'Steinkohle',
    biogas: 'Biogas',
    biogas_calorific_active: 'Biogas (Brennwert aktiv)',
    hybrid_heating: 'Hybridheizung'

}

export const heatingCostsDivision = {
    area: 'Nur nach Fläche',
    person: 'Nur nach Personen',
    usage: 'Nur nach Verbrauch',
    '30|70': '30/70',
    '40|60': '40/60',
    '50|50': '50/50'
}

export const warmWaterCostsDivision = {
    area: 'Nur nach Fläche',
    person: 'Nur nach Personen',
    usage: 'Nur nach Verbrauch',
    '30|70': '30/70',
    '40|60': '40/60',
    '50|50': '50/50'
}

export const sort = {
    initial: 'Anfangsbestand',
    purchase: 'Zukauf',
    final: 'Endbestand'
}

export const meterType = {
    Heat: 'Wärmemengenzähler',
    HCA: 'Heizkostenverteiler',
    WWater: 'Warmwasserzähler',
    Water: 'Kaltwasserzähler',
    Cold: 'Kältezähler'
}

export const defaultCost = {
    gas: 0.09,
    oil: 0.06,
    remote: 0.1,
    heat_pump: 0.26,
    pellet: 0.07
}

export const heatingSystemsEmissions = {
    gas: 0.247,
    oil: 0.318,
    remote: 0.198,
    heat_pump: 0,
    pellet: 0.023
}

export const defaultHeatingCost = (property) => {
    const defaultConsumption = {
        gas: 82,
        oil: 93,
        remote: 72,
        heat_pump: 24,
        pellet: 60
    }

    const heating = defaultConsumption[property.heating]
    const size = property.apartments.reduce(
        (previousValue, currentValue) => previousValue + currentValue.size_sqm
    )
    const consumption = size * heating
    return defaultCost * consumption
}

export const floorOptions = [
    { value: -2, label: '2. UG'},
    { value: -1, label: 'UG' },
    { value: 0, label: 'EG' },
    { value: 1, label: '1. OG' },
    { value: 2, label: '2. OG' },
    { value: 3, label: '3. OG' },
    { value: 4, label: '4. OG' },
    { value: 5, label: '5. OG' },
    { value: 6, label: '6. OG' },
    { value: 7, label: '7. OG' },
    { value: 8, label: '8. OG' },
    { value: 9, label: '9. OG' },
    { value: 10, label: '10. OG' },
    { value: 11, label: '11. OG' },
    { value: 12, label: '12. OG' },
    { value: 13, label: '13. OG' },
    { value: 14, label: '14. OG' },
    { value: 15, label: '15. OG' },
    { value: 999, label: 'DG' }
]

// Offizielle Gradtag Tabelle nach Monat = Index
export const monthTable = [0, 170, 150, 130, 80, 40, 13, 13, 14, 30, 80, 120, 160]

export const defaultHeatingPerSqm = { //kWh / m²
    gas: 137,
    oil: 149,
    remote: 119,
    heat_pump: 39,
    pellet: 119,
    default: 114
}

export const emissionTable = {
    52: [95, '#f77552'],
    47: [80, '#f77552'],
    42: [70, '#f77552'],
    37: [60, '#f0be02'],
    32: [50, '#f0be02'],
    27: [40, '#f0be02'],
    22: [30, '#f0be02'],
    17: [20, '#79bf45'],
    12: [10, '#79bf45'],
    0: [0, '#79bf45']
}

// Calculation example for gas: 82 = max Eff, 214 = min Eff => step_size = (214-82) / 3 = 44, step_0 = 82, step_1 = step_0+44, step_2=step_1+44, step_3=step2+44
export const heatingEfficiencyTable = {
    gas: [82, 126, 170, 214],
    oil: [93, 137, 181, 225],
    remote: [72, 114, 156, 198],
    heat_pump: [24, 46, 68, 90],
    pellet: [60, 113, 166, 219]
}

export const calculateSingleDayUsage = (begin, end, energyConsumption, property) => {
    let sumEnergy = 0
    let sumDays = 0

    begin = moment(begin)
    while (begin.isBefore(end, 'month')) {
        const dateFormatted = moment(begin).subtract(1, 'month').toDate().toDateString()

        if (energyConsumption && energyConsumption.hasOwnProperty(dateFormatted) && energyConsumption[dateFormatted] > 0) {
            sumEnergy += energyConsumption[dateFormatted] ?? 0
            sumDays += monthTable[moment(begin).subtract(1, 'month').format('M')]
        }

        begin.add(1, 'month')
    }

    if (sumEnergy > 0 && sumDays > 0) {
        return sumEnergy / sumDays
    }

    if (property?.heating) {
        const defaultEnergyPerSqm = defaultHeatingPerSqm[property.heating]
        if (defaultEnergyPerSqm) {
            return defaultEnergyPerSqm * qmSumByProperties([property]) / 1000
        }
    }

    return 0
}

/** @deprecated **/
export const sumHeatingWithForecast = (begin, end, devices, property) => {
    const energyConsumption = monthlyDeviceDifference(devices)
    energyConsumption.pop() // Remove last empty value

    const date = moment(begin).subtract('month').startOf('month')
    const singleDayEnergy = calculateSingleDayUsage(date, end, energyConsumption, property)

    let sum = 0
    if (singleDayEnergy) {
        const fillDate = moment(begin).subtract(1, 'month').startOf('month')
        while (fillDate.isBefore(end, 'month')) {
            const dateFormatted = fillDate.add(1, 'month').toDate().toDateString()

            if (energyConsumption.hasOwnProperty(dateFormatted) && energyConsumption[dateFormatted] > 0) {
                sum += energyConsumption[dateFormatted]
            } else {
                sum += parseFloat(monthTable[fillDate.format('M')] * singleDayEnergy)
                console.log('Default', parseFloat(monthTable[fillDate.format('M')] * singleDayEnergy))
            }
        }
    }

    return sum
}

export const sumHeatingWithForecastData = (begin, end, energyConsumption, property) => {
    const date = moment().subtract('month').subtract(1, 'year').startOf('month')
    const endDate = moment().startOf('month')
    const singleDayEnergy = calculateSingleDayUsage(date, endDate, energyConsumption, property)


    let sum = 0
    if (singleDayEnergy) {
        const fillDate = moment(begin).subtract(1, 'month').startOf('month')
        while (fillDate.isBefore(end, 'month')) {
            const dateFormatted = fillDate.add(1, 'month').toDate().toDateString()
            if (energyConsumption && energyConsumption.hasOwnProperty(dateFormatted) && energyConsumption[dateFormatted] > 0) {
                sum += energyConsumption[dateFormatted]
            } else {
                sum += parseFloat(monthTable[fillDate.format('M')] * singleDayEnergy)
            }
        }
    }

    return sum
}

export const energyForecast = (energyConsumption) => {
    const date = moment().subtract('month').subtract(1, 'year').startOf('month')
    const currentMonth = moment().startOf('month')

    const singleDayEnergy = calculateSingleDayUsage(date, currentMonth, energyConsumption)
    const forecast = []

    const toDate = moment().startOf('month').subtract(1, 'month')
    const toEnd = moment().endOf('year')
    while (toDate.isBefore(toEnd, 'month')) {
        const dateFormatted = toDate.add(1, 'month').toDate().toDateString()

        forecast[dateFormatted] = parseFloat(monthTable[toDate.format('M')] * singleDayEnergy)
    }
    return forecast
}

export const getServiceCharge = (property, apartment) => {
    const { tenants: mapping } = apartment
    let currentValue = 0, optimalValue = 0, percentage = 0

    if (mapping?.length > 0 && property?.id) {
        const apartmentMapping = mapping[mapping.length - 1]
        if (!apartmentMapping || !apartmentMapping?.service_charge_cost) {
            return { percentage: 0, optimalValue: 0, currentValue: 0 }
        }

        const currentServiceCharge = apartmentMapping.service_charge_cost[apartmentMapping.service_charge_cost.length - 1]
        const result = (+currentServiceCharge?.heating_cost_deduction || 0) + (+currentServiceCharge?.service_charge_deduction || 0)
        if (result > 0) {
            currentValue = result
        }

        const overheadCostsSum = Object.values(property.cost_per_sqm).reduce((acc, current) => acc + current, 0)

        const begin = moment().subtract(1, 'year')

        const filteredDevices = filterDevices(apartment.devices, { begin, type: ['Heat', 'HCA'] })
        const calculatedSum = sumHeatingWithForecast(begin, moment(begin).add(1, 'year'), filteredDevices)

        let heatingCost = 0
        if (property) {
            heatingCost = calculatedSum * defaultCost[property.heating] / 12
            if (property.energy_cost.length > 0) {
                const now = moment().subtract(1, 'month').startOf('month')

                // Ein Jahr zurück -> alle Preise innerhalb diesen Jahres addieren -> durchschnitt berechnen
                const lastYear = moment().subtract(1, 'year').startOf('month')
                const prices = []
                while (lastYear <= now) {
                    property.energy_cost.map((energy_cost) => {
                        if (moment(energy_cost.begin).isSameOrBefore(lastYear) && (moment(energy_cost.end).isAfter(lastYear) || !energy_cost.end)) {
                            prices.push(energy_cost.cost)
                        }
                    })

                    lastYear.add(1, 'month').startOf('month')
                }

                const sumPrices = prices.reduce((acc, current) => acc + current, 0)
                const averageCost = sumPrices / prices.length
                heatingCost = calculatedSum * averageCost / 12
            }
        }

        const optimal = heatingCost + (apartment.size_sqm * overheadCostsSum)
        if (!isNaN(optimal)) {
            optimalValue = optimal
        }

        percentage = percentageDifference(result, optimal)

        return { percentage, optimalValue, currentValue }
    }

    return { percentage: 0, optimalValue: 0, currentValue: 0 }
}

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state')
        if (serializedState === null) {
            return undefined
        }
        return JSON.parse(serializedState)
    } catch (err) {
        return undefined
    }
}

export const percentageOfValidFields = (schema, values) => {
    let fieldCount = 0
    let errorCount = 0

    let result = 100
    try {
        schema.validateSync(values, { abortEarly: false })
    } catch (e) {
        errorCount = e.errors.length
        for (const [key, value] of Object.entries(values)) {
            if (value && Array.isArray(value)) {
                value.map((item) => {
                    fieldCount += Object.keys(item).length
                })
            } else if (typeof value === 'object' && value !== null) {
                fieldCount += Object.keys(value).length
            } else {
                fieldCount += 1
            }
        }

        result = ((fieldCount - errorCount) / fieldCount * 100).toFixed(0)
    }

    return result
}

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem('state', serializedState)
    } catch (e) {
        console.log('Error saving state', e)
        // ignore write errors
    }
}

export const clearDataOnLogout = () => {
    const config = useJwt.jwtConfig
    localStorage.removeItem('state')
    localStorage.removeItem('userData')
    localStorage.removeItem(config.storageTokenKeyName)
    localStorage.removeItem(config.storageRefreshTokenKeyName)
}

export const rememberPaginateState = (page, limit, sort, prefix = 'default') => {
    ls.set(`${prefix}-list-page`, page, {ttl: 30 * 60})
    ls.set(`${prefix}-list-limit`, limit, {ttl: 30 * 60})
    ls.set(`${prefix}-list-sort`, sort, {ttl: 30 * 60})
}

export const loadRememberedPaginateState = (type, prefix = 'default') => {
    return ls.get(`${prefix}-list-${type}`)
}

export const floorToString = (floor) => {
    if (floor < 0) {
        return 'KG'
    } else if (floor === 0) {
        return 'EG'
    } else if (floor === 999) {
        return 'DG'
    } else {
        return `${floor}. OG`
    }
}

export const getCurrentTenantOrOwnerName = (apartment, mode = 'tenant') => {
    if (!apartment || apartment[`${mode}s`].length === 0) {
        return null
    }

    return apartment[mode]
        ? `${apartment[mode].last_name}`
        : 'Leerstand'
}

export const getCurrentTenantMapping = (apartment) => {
    if (!apartment) {
        return null
    }

    return apartment.tenant ? apartment.tenants.find((apartment_tenant) => apartment_tenant.tenant.id === apartment.tenant.id) : apartment.tenants[apartment.tenants.length - 1]
}

export const userDocumentTypes = [
    'Abrechnungsunterlagen',
    'Angebot',
    'Aushang',
    'Dienstleistungsrechnung',
    'Geräterechnung',
    'Grundriss',
    'Hausordnung',
    'Informationsschreiben',
    'Lageplan',
    'Mietvertrag',
    'Nebenkostenerhöhung',
    'Rechnung',
    'Sonstiges'
]

export const deviceTypes = [
    {
        value: 'Warmwasserzähler',
        label: 'Warmwasserzähler'
    },
    {
        value: 'Kaltwasserzähler',
        label: 'Kaltwasserzähler'
    },
    {
        value: 'Heizkostenverteiler',
        label: 'Heizkostenverteiler'
    },
    {
        value: 'Wärmemengenzähler',
        label: 'Wärmemengenzähler'
    },
    {
        value: 'Gaszähler',
        label: 'Gaszähler'
    },
    {
        value: 'Ölzähler',
        label: 'Ölzähler'
    },
    {
        value: 'Sonderzähler',
        label: 'Sonderzähler'
    }
]
export const measurementSites = [
    {
        value: 'Gesamtes Warmwasser der Immobilie',
        label: 'Gesamtes Warmwasser der Immobilie',
        deviceType: 'Warmwasserzähler'
    },
    {
        value: 'Gesamtes Kaltwasser der Immobilie',
        label: 'Gesamtes Kaltwasser der Immobilie',
        deviceType: 'Kaltwasserzähler'
    },
    {
        value: 'Gesamte Energie der Immobilie',
        label: 'Gesamte Energie der Immobilie',
        deviceType: 'Wärmemengenzähler'
    },
    {
        value: 'Warmwasserenergie der Immobilie',
        label: 'Warmwasserenergie der Immobilie',
        deviceType: 'Wärmemengenzähler'
    },
    {
        value: 'Heizenergie der Immobilie',
        label: 'Heizenergie der Immobilie',
        deviceType: 'Wärmemengenzähler'
    },
    {
        value: 'Gesamtes Öl der Immobilie',
        label: 'Gesamtes Öl der Immobilie',
        deviceType: 'Ölzähler'
    },
    {
        value: 'Öl für Heizenergie der Immobilie',
        label: 'Öl für Heizenergie der Immobilie',
        deviceType: 'Ölzähler'
    },
    {
        value: 'Öl für Warmwasserenergie der Immobilie',
        label: 'Öl für Warmwasserenergie der Immobilie',
        deviceType: 'Ölzähler'
    },
    {
        value: 'Gesamtes Gas der Immobilie',
        label: 'Gesamtes Gas der Immobilie',
        deviceType: 'Gaszähler'
    },
    {
        value: 'Gas für Heizenergie der Immobilie',
        label: 'Gas für Heizenergie der Immobilie',
        deviceType: 'Gaszähler'
    },
    {
        value: 'Gas für Warmwasserenergie der Immobilie',
        label: 'Gas für Warmwasserenergie der Immobilie',
        deviceType: 'Gaszähler'
    },
    {
        value: 'Allgemeinstrom',
        label: 'Allgemeinstrom',
        deviceType: 'Stromzähler'
    },
    {
        value: 'Betriebsstrom Heizungsanlage',
        label: 'Betriebsstrom Heizungsanlage',
        deviceType: 'Stromzähler'
    },
    {
        value: 'Für eigene Bezeichnung tippen',
        label: 'Für eigene Bezeichnung tippen'
    }
]

export const allWidgets = {
    energySum: {
        key: 'energySum',
        name: EnergySumWidget,
        type: 'Heat',
        layout: { x: 0, y: 0, w: 12, minW: 12, maxW: 12, h: 9, minH: 9, maxH: 9 }
    },
    welcome: {
        key: 'welcome',
        name: WelcomeWidget,
        layout: { x: 0, y: 0, w: 6, minW: 6, maxW: 6, h: 5, minH: 5, maxH: 5 }
    },
    lastMonthWater: {
        key: 'lastMonthWater',
        name: LastMonthWaterWidget,
        layout: { x: 0, y: 6, w: 6, minW: 6, maxW: 6, h: 6, minH: 6, maxH: 6 }
    },
    lastMonth: {
        key: 'lastMonth',
        name: LastMonthWidget,
        type: 'Heat',
        layout: { x: 6, y: 0, w: 6, minW: 6, maxW: 6, h: 5, minH: 5, maxH: 5 }
    },
    waterConsumption: {
        key: 'waterConsumption',
        name: WaterConsumptionWidget,
        type: 'Water',
        layout: { x: 6, y: 0, w: 6, minW: 6, maxW: 6, h: 8, minH: 8, maxH: 8 }
    },
    billing: {
        key: 'billing',
        name: BillingWidget,
        layout: { x: 0, y: 0, w: 6, minW: 6, maxW: 6, h: 5, minH: 5, maxH: 5 }
    },
    energyUsage: {
        key: 'energyUsage',
        name: EnergyUsageWidget,
        type: 'Heat',
        layout: { x: 6, y: 0, w: 6, minW: 6, maxW: 6, h: 9, minH: 9, maxH: 9 }
    },
    efficiency: {
        key: 'efficiency',
        name: EfficiencyWidget,
        type: 'Heat',
        layout: { x: 0, y: 0, w: 6, minW: 6, maxW: 6, h: 6, minH: 6, maxH: 6 }
    },
    costForecast: {
        key: 'costForecast',
        name: CostForecastWidget,
        type: 'Heat',
        layout: { x: 6, y: 0, w: 6, minW: 6, maxW: 6, h: 8, minH: 8, maxH: 8 }
    },
    heatingEnergy: {
        key: 'heatingEnergy',
        name: HeatingEnergyWidget,
        type: 'Heat',
        layout: { x: 0, y: 0, w: 6, minW: 6, maxW: 6, h: 5, minH: 5, maxH: 8 }
    },
    heatingSystems: {
        key: 'heatingSystems',
        name: HeatingSystemsWidget,
        type: 'Heat',
        layout: { x: 0, y: 0, w: 6, minW: 6, maxW: 6, h: 5, minH: 5, maxH: 8 }
    },
    energyForecast: {
        key: 'energyForecast',
        name: EnergyForecastWidget,
        type: 'Heat',
        layout: { x: 0, y: 0, w: 6, minW: 6, maxW: 6, h: 5, minH: 5, maxH: 8 }
    },
    ownerShare: {
        key: 'ownerShare',
        name: OwnerShareWidget,
        type: 'Heat',
        layout: { x: 0, y: 0, w: 6, minW: 6, maxW: 6, h: 6, minH: 6, maxH: 6 }
    },
    dataSheet: {
        key: 'dataSheet',
        name: DataSheetWidget,
        type: 'Heat',
        layout: { x: 6, y: 0, w: 6, minW: 6, maxW: 6, h: 5, minH: 5, maxH: 5 }
    },
    leckage: {
        key: 'leckage',
        name: LeakageWidget,
        type: 'Water',
        layout: { x: 6, y: 0, w: 6, minW: 6, maxW: 6, h: 5, minH: 5, maxH: 5 }
    }
}

export const changeWidgetSize = (name, layout, widgets) => {
    // You have to override the default values, if state changes everything is reset
    if (allWidgets.heatingEnergy?.layout?.h && allWidgets.heatingEnergy?.layout?.minH) {
        allWidgets.heatingEnergy.layout.h = layout.h
        allWidgets.heatingEnergy.layout.minH = layout.minH
    }

    const index = widgets.findIndex((value) => value.key === 'heatingEnergy')
    const newWidget = {
        ...widgets[index],
        layout: {
            ...widgets[index].layout,
            ...layout
        }
    }
    const newWidgets = [...widgets]
    newWidgets[index] = newWidget
    return newWidgets
}

export const filterWidgets = (usage, widgets) => {
    const filteredWidgets = []

    if (usage && Object.keys(usage).length > 0) {
        const deviceTypes = []
        if (Object.keys(usage.heating_data).length > 0) {
            deviceTypes.push('HCA')
            deviceTypes.push('Heat')
        }

        if (Object.keys(usage.water_data).length > 0) {
            deviceTypes.push('Water')
        }

        if (Object.keys(usage.wwater_data).length > 0) {
            deviceTypes.push('WWater')
        }

        for (const widget of widgets) {
            if (!widget.type || (widget.type && deviceTypes.indexOf(widget.type) > -1)) {
                filteredWidgets.push(widget)
            }
        }
    } else {
        for (const widget of widgets) {
            filteredWidgets.push(widget)
        }
    }

    return filteredWidgets
}

export const triggerResize = (delay = 500) => {
    setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
    }, delay)
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
    value,
    formatting = { month: 'short', day: '2-digit', year: 'numeric' }
) => {
    if (!value) {
        return value
    }

    try {
        value = new Intl.DateTimeFormat('de-DE', formatting).format(new Date(value))
    } catch (e) {
        console.log(e)
    }

    return value
}

export const formatCurrency = (value, format = 'de-DE', currency = 'EUR') => {
    return new Intl.NumberFormat(format, {
        style: 'currency',
        currency
    }).format(value)
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
    const date = new Date(value)
    let formatting = { month: 'short', day: 'numeric' }

    if (toTimeForCurrentDay && isToday(date)) {
        formatting = { hour: 'numeric', minute: 'numeric' }
    }

    return new Intl.DateTimeFormat('de-DE', formatting).format(new Date(value))
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: '#7367f0', // for option hover bg-color
        primary: '#7367f0', // for selected option bg-color
        neutral10: '#7367f0', // for tags bg-color
        neutral20: '#ededed', // for input border-color
        neutral30: '#ededed' // for input hover border-color
    }
})

export const convertBooleanStrings = (obj) => {
    if (typeof obj === 'object') {
        if (Array.isArray(obj)) {
            // Wenn es sich um ein Array handelt, durchlaufe jedes Element
            for (let i = 0; i < obj.length; i++) {
                obj[i] = convertBooleanStrings(obj[i]);
            }
        } else {
            // Wenn es sich um ein Objekt handelt, durchlaufe jede Eigenschaft
            for (const prop in obj) {
                if (obj.hasOwnProperty(prop)) {
                    try {
                        obj[prop] = convertBooleanStrings(obj[prop]);
                    } catch (e) {
                        console.log(e)
                    }
                }
            }
        }
    } else if (typeof obj === 'string') {
        // Wenn es sich um einen String handelt, konvertiere "true" oder "false" zu boolesch
        if (obj.toLowerCase() === 'true') {
            return true;
        } else if (obj.toLowerCase() === 'false') {
            return false;
        }
    }

    // Gib das aktualisierte Objekt zurück
    return obj;
}

export * from './sorting'
export * from './filtering'

export const needPaddingForPaths = ['/onboarding']
