import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ThemeColors } from '../../../../utility/context/ThemeColors'

const BillingWidget = (props) => {
    const { colors } = useContext(ThemeColors)
    const properties = useSelector(state => state[props.store].properties)
    const [propertyCount, setPropertyCount] = useState(0)
    const [billingCount, setBillingCount] = useState(0)
    const [percentage, setPercentage] = useState(0)

    useEffect(() => {
        const lastYearBillings = properties.filter((property) => property.billing).
            map((property) => property.billing).
            flat().
            filter((billing) => parseInt(moment(billing.begin).format('Y')) === moment().format('Y') - 1)

        setBillingCount(Math.min(lastYearBillings.length, properties.length))
        setPropertyCount(properties.length)
        setPercentage(Math.min(100, lastYearBillings.length / properties.length * 100))
    }, [properties])

    return (
        <>
            <h4>Abrechnungen für das Jahr {moment().format('YYYY') - 1}</h4>
            <div className={'d-flex gap-4 pb-3 pb-md-0'}>
                <div style={{ background: '#ddd', borderRadius: '10px', height: '30px', width: '100%' }}>
                    <div style={{
                        background: colors.primary.main,
                        width: `${percentage}%`,
                        height: '30px',
                        borderTopRightRadius: '10px',
                        borderBottomRightRadius: '10px'
                    }}>
                        <span style={{
                            color: `${billingCount === 0 ? '#10163a' : '#fff'}`,
                            lineHeight: '30px',
                            paddingLeft: '1rem',
                            position: 'absolute'
                        }}>Abgeschlossene</span>
                    </div>
                    <span style={{
                        position: 'relative',
                        left: `${percentage}%`,
                        height: '2rem',
                        width: '1px',
                        background: '#ddd',
                        display: 'inline-block'
                    }}></span>
                    <span style={{
                        position: 'relative',
                        left: `${percentage}%`,
                        top: '1.5rem',
                        marginLeft: '-15px',
                        marginBottom: '20px'
                    }}>{billingCount} / {propertyCount}</span>
                </div>
            </div>
        </>
    )
}

export default BillingWidget