import { useContext, useEffect, useState } from 'react'
import { ThemeColors } from '../../../../../utility/context/ThemeColors'
import Chart from 'react-apexcharts'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { hasHeatingData } from '../../../../../utility/DeviceData'

const de = require('apexcharts/dist/locales/de.json')

const EnergySumWidget = (props) => {
    const { colors } = useContext(ThemeColors)
    const [chartValues, setChartValues] = useState([])
    const [oldChartValues, setOldChartValues] = useState([])
    const usage = useSelector(state => state[props.store].usage)
    const begin = moment().subtract(2, 'year').subtract(7, 'month').startOf('month')
    const end = useSelector(state => state[props.store].end)
    const MWh = (props.store === 'defaultDashboard')

    const legend = []
    const legendDate = moment().subtract(6, 'month').set('date', 1)
    while (legendDate.isSameOrBefore(moment().add(6, 'month').set('date', 1))) {
        legend.push(moment(legendDate).toDate().toDateString())
        legendDate.add(1, 'month')
    }

    useEffect(() => {
        if (hasHeatingData(usage)) {
            const sum = usage.heating_data

            const currentValueDate = moment().subtract(6, 'month')
            const date = moment(begin).set('date', 1)
            const switchDate = moment().subtract(1, 'year').subtract(7, 'month')
            const values = [], oldValues = []
            while (date.isBefore(end)) {
                const valueDateFormatted = moment(date).toDate().toDateString()
                const keyDateFormatted = moment(date).subtract(1, 'month').toDate().toDateString()

                if (date.isAfter(currentValueDate)) {
                    values.push([keyDateFormatted, sum[valueDateFormatted] ?? 0])
                } else if (date.isAfter(switchDate)) {
                    oldValues.push([keyDateFormatted, sum[valueDateFormatted] ?? 0])
                }
                date.add(1, 'month')
            }

            setOldChartValues(oldValues.slice(1))
            setChartValues(values)
        }
    }, [usage])

    const options = {
        chart: {
            locales: [de],
            defaultLocale: 'de',
            toolbar: {
                show: false
            }
        },
        legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'left',
            offsetX: -35
        },
        markers: {
            size: 0,
            style: 'hollow'
        },
        xaxis: {
            type: 'category',
            categories: legend,
            labels: {
                formatter: (value) => {
                    if (typeof value === 'undefined') {
                        return
                    }

                    return moment(value).format('MMM')
                },
                style: {
                    colors: colors.secondary.main,
                    fontSize: '1rem'
                }
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 2,
            curve: 'smooth'
        },
        yaxis: {
            tickAmount: 5,
            labels: {
                offsetX: -35,
                style: {
                    colors: colors.secondary.main,
                    fontSize: '1rem'
                },
                formatter: (value) => {
                    if (!value) {
                        return 0
                    }

                    return MWh ? `${(value / 1000).toFixed(0)}` : `${value.toFixed(0)}`
                }
            },
            title: {
                text: MWh ? 'MWh' : 'KWh',
                rotate: 0,
                offsetX: 30,
                offsetY: -95,
                style: {
                    cssClass: 'apexcharts-yaxis-title',
                    fontSize: '1rem'
                }
            }
        },
        grid: {
            padding: {
                left: -20,
                right: 0,
                top: 30
            }
        },
        tooltip: {
            x: {
                format: 'MMMM'
            },
            y: {
                formatter: (value) => {
                    if (!value) {
                        return 0
                    }

                    return MWh ? `${(value / 1000).toLocaleString('de-DE', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} MWh` : `${value.toLocaleString('de-DE',
                        { maximumFractionDigits: 0 })} KWh`
                }
            }
        },
        responsive: [
            {
                breakpoint: 768,
                options: {
                    yaxis: {
                        show: false
                    },
                    xaxis: {
                        labels: {
                            rotate: -90,
                            rotateAlways: true,
                            trim: true
                        }
                    }
                }
            }
        ]
    }
    const series = [
        {
            name: 'Aktuell',
            data: chartValues.map(item => (item[1]).toFixed(2)),
            type: 'line',
            color: colors.primary.main
        },
        {
            name: 'Vorjahr',
            data: oldChartValues.map(item => (item[1]).toFixed(2)),
            type: 'area',
            color: '#eee'
        }
    ]

    return (
        <>
            <h4>Heizenergie</h4>
            <div className={'widget-body d-flex gap-4'}>
                <Chart options={options} series={series} height={250} style={{ width: '100%' }}/>
            </div>
        </>
    )
}

export default EnergySumWidget