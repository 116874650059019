import axios from 'axios'
import * as Sentry from '@sentry/react'

class ApiServiceClass {

    async get(url, config = {}) {
        try {
            const response = await axios.get(url, config)
            return { success: true, data: response.data.data || response.data, count: response.data?.totalCount }
        } catch (e) {
            Sentry.captureException(e)
            return { success: false, exception: e }
        }
    }

    async post(url, body, config = {}) {
        try {
            const response = await axios.post(url, body, config)
            return { success: true, data: response.data }
        } catch (e) {
            Sentry.captureException(e)
            return { success: false, exception: e }
        }
    }

    async patch(url, body, config = {}) {
        try {
            const response = await axios.patch(url, body, config)
            return { success: true, data: response.data }
        } catch (e) {
            Sentry.captureException(e)
            return { success: false, exception: e }
        }
    }

    async delete(url) {
        try {
            const response = await axios.delete(url)
            return { success: true, data: response.data }
        } catch (e) {
            Sentry.captureException(e)
            return { success: false, exception: e }
        }
    }
}

const ApiService = new ApiServiceClass()

export { ApiService }