import { useSelector } from 'react-redux'
import { useContext, useEffect, useState } from 'react'
import { Row } from 'reactstrap'
import moment from 'moment/moment'
import { calculateSingleDayUsage, percentageDifference, sumHeatingWithForecastData } from '../../../../utility/Utils'
import { ThemeColors } from '../../../../utility/context/ThemeColors'
import { hasHeatingData } from '../../../../utility/DeviceData'

const EnergyForecastWidget = () => {
    const { colors } = useContext(ThemeColors)
    const property = useSelector(state => state['property'].selectedProperty)
    const [sumHeatPercentage, setSumHeatPercentage] = useState(0)
    const [sumPreviousYear, setSumPreviousYear] = useState(0)
    const [sumAnnualEnergyForecast, setSumAnnualEnergyForecast] = useState(0)

    useEffect(() => {
        if (property && hasHeatingData(property.usage)) {
            let date = moment().set('date', 1)
            let previousDate = moment().subtract(1, 'year').set('date', 1)
            const previousYear = sumHeatingWithForecastData(previousDate, date, property.usage.heating_data, property)
            if (previousYear) {
                setSumPreviousYear(previousYear)
            }
            date = moment().endOf('year')
            previousDate = moment().startOf('year')
            const annualEnergyForecast = sumHeatingWithForecastData(previousDate, date, property.usage.heating_data, property)
            if (annualEnergyForecast) {
                setSumAnnualEnergyForecast(annualEnergyForecast)
            }

            if (previousYear > 0 && annualEnergyForecast > 0) {
                setSumHeatPercentage(percentageDifference(annualEnergyForecast, previousYear))
            }
        }
    }, [property])

    const calculateWidth = (current, previous, percentage, mode) => {
        if (mode === 'current') {
            return previous < current ? '100%' : `${100 + (parseFloat(percentage) / 2)}%`
        } else {
            return previous > current ? '100%' : `${100 - (parseFloat(percentage) / 2)}%`
        }
    }

    return (
        <>
            <h4 className={'mb-0'}>Energieprognose bis zum 31.12</h4>
            <div className={'container-fluid widget-body'}>
                <Row className={`mt-1 d-flex flex-nowrap gap-4`}>
                    <div className="w-100">
                        <p className={'mb-0'}>Prognose</p>
                        <div style={{
                            background: colors.primary.main,
                            height: '32px',
                            minWidth: '150px',
                            width: calculateWidth(sumAnnualEnergyForecast, sumPreviousYear, sumHeatPercentage, 'current'),
                            borderTopRightRadius: '.75rem',
                            borderBottomRightRadius: '.75rem'
                        }} className={'mb-1'}>
                          <span style={{ lineHeight: '32px' }} className={'text-white font-weight-bold font-medium-1 ml-1'}>
                            {sumAnnualEnergyForecast.toLocaleString('de-DE', { maximumFractionDigits: 0 })} kWh
                              {sumPreviousYear > 0 && sumAnnualEnergyForecast > 0 && <>
                                  ({sumHeatPercentage < 0 ? '' : '+'} {sumHeatPercentage} %)
                              </>}
                          </span>
                        </div>
                        <p className={'mb-0'}>Vorjahr</p>
                        <div style={{
                            background: colors.secondary.main,
                            height: '32px',
                            minWidth: '150px',
                            width: calculateWidth(sumAnnualEnergyForecast, sumPreviousYear, sumHeatPercentage, 'previous'),
                            borderTopRightRadius: '.75rem',
                            borderBottomRightRadius: '.75rem'
                        }}>
                            <span style={{ lineHeight: '32px' }} className={'text-white font-weight-bold font-medium-1 ml-1'}>{sumPreviousYear.toLocaleString('de-DE',
                                { maximumFractionDigits: 0 })} kWh</span>
                        </div>
                    </div>
                </Row>
            </div>
        </>
    )
}
export default EnergyForecastWidget
