import { useSelector } from 'react-redux'
import { useContext, useEffect, useState } from 'react'
import { Row } from 'reactstrap'
import moment from 'moment/moment'
import { changeWidgetSize, percentageDifference } from '../../../../utility/Utils'
import { ThemeColors } from '../../../../utility/context/ThemeColors'
import { hasHeatingData, hasUsageData, hasWWaterData } from '../../../../utility/DeviceData'

const HeatingEnergyWidget = ({ store, widgets, setWidgets }) => {
    const { colors } = useContext(ThemeColors)
    const properties = useSelector(state => state[store].properties)
    const usage = useSelector(state => state[store].usage)
    const [sumHeat, setSumHeat] = useState(0)
    const [sumWarmWater, setSumWarmWater] = useState(0)
    const [sumHeatPercentage, setSumHeatPercentage] = useState(0)

    const [sumPreviousHeat, setSumPreviousHeat] = useState(0)
    const [sumPreviousWarmWater, setSumPreviousWarmWater] = useState(0)
    const [sumWarmWaterPercentage, setSumWarmWaterPercentage] = useState(0)
    const [hasWarmWaterDevices, setHasWarmWaterDevices] = useState(false)

    useEffect(() => {
        if (!hasUsageData(usage)) {
            return
        }

        const date = moment().set('date', 1)
        const previousDate = moment().subtract(1, 'year').set('date', 1)

        if (hasHeatingData(usage)) {
            const heatDifference = usage.heating_data
            const previousHeat = heatDifference[previousDate.toDate().toDateString()] ?? 0.0
            const heat = heatDifference[date.toDate().toDateString()] ?? 0.0
            if (heat) {
                setSumHeat(heat)
                setSumPreviousHeat(previousHeat)
                setSumHeatPercentage(percentageDifference(heat, previousHeat))

                // If widget is placed on a dashboard (also used in PDF generation)
                if (widgets) {
                    setWidgets(changeWidgetSize('heatingEnergy', { h: 5, minH: 5 }, widgets))
                }
            }
        }

        if (hasWWaterData(usage) && properties && properties.length > 0) {
            const warmWaterDifference = usage.wwater_data
            const previousWarmWater = (warmWaterDifference[previousDate.toDate().toDateString()] ?? 0.0) * 2.5 * (properties[0].warm_water_temperature - 10)
            const warmWater = (warmWaterDifference[date.toDate().toDateString()] ?? 0.0) * 2.5 * (properties[0].warm_water_temperature - 10)
            if (warmWater) {
                setSumWarmWater(warmWater)
                setSumPreviousWarmWater(previousWarmWater)
                setSumWarmWaterPercentage(percentageDifference(warmWater, previousWarmWater))
                setHasWarmWaterDevices(true)

                // If widget is placed on a dashboard (also used in PDF generation)
                if (widgets) {
                    setWidgets(changeWidgetSize('heatingEnergy', { h: 8, minH: 8 }, widgets))
                }
            }
        }
    }, [usage])

    const calculateWidth = (current, previous, percentage, mode) => {
        if (mode === 'current') {
            return previous < current ? '100%' : `${100 + (parseFloat(percentage) / 2)}%`
        } else {
            return previous > current ? '100%' : `${100 - (parseFloat(percentage) / 2)}%`
        }
    }

    return (
        <>
            <h4>Vergleich zum Vorjahresmonat</h4>
            <div className={'container-fluid widget-body'}>
                <Row className={`my-2 mb-2 d-flex flex-nowrap gap-4 mr-0 ml-0`}>
                    <div className={'d-flex flex-wrap align-items-center justify-content-center gap-2'} style={{ width: '30%' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="44" width="44" style={{ transform: 'scale(1.15)' }}>
                            <path fill="orange"
                                  d="M11.65 42q-2.35 0-4-1.625T6 36.4V16h3v20.4q0 1.1.775 1.85t1.875.75q1.1 0 1.85-.75t.75-1.85V11.65q0-2.35 1.65-4t4-1.65q2.35 0 3.975 1.65t1.625 4V36.4q0 1.1.775 1.85t1.875.75q1.1 0 1.85-.75t.75-1.85V11.65q0-2.35 1.65-4t4-1.65q2.35 0 3.975 1.65t1.625 4V32h-3V11.65q0-1.1-.75-1.875Q37.5 9 36.4 9t-1.875.775q-.775.775-.775 1.875V36.4q0 2.35-1.625 3.975T28.15 42q-2.35 0-4-1.625T22.5 36.4V11.65q0-1.1-.75-1.875Q21 9 19.9 9t-1.875.775q-.775.775-.775 1.875V36.4q0 2.35-1.625 3.975T11.65 42Z"/>
                        </svg>
                        <p className={'font-medium-1 mb-0 w-100 text-center'}>Heizenergie</p>
                    </div>
                    <div className="w-100">
                        <div style={{
                            background: colors.primary.main,
                            height: '32px',
                            minWidth: '150px',
                            width: calculateWidth(sumHeat, sumPreviousHeat, sumHeatPercentage, 'current'),
                            borderTopRightRadius: '.75rem',
                            borderBottomRightRadius: '.75rem'
                        }} className={'mb-1'}>
              <span style={{ lineHeight: '32px' }} className={'text-white font-weight-bold font-medium-1 ml-1'}>
                {sumHeat.toLocaleString('de-DE', { maximumFractionDigits: 0 })} kWh ({sumHeatPercentage < 0 ? '' : '+'}{sumHeatPercentage} %)
              </span>
                        </div>
                        <div style={{
                            background: colors.primary.light,
                            height: '32px',
                            minWidth: '150px',
                            width: calculateWidth(sumHeat, sumPreviousHeat, sumHeatPercentage, 'previous'),
                            borderTopRightRadius: '.75rem',
                            borderBottomRightRadius: '.75rem'
                        }}>
                            <span style={{ lineHeight: '32px' }} className={'text-white font-weight-bold font-medium-1 ml-1'}>{sumPreviousHeat.toLocaleString('de-DE',
                                { maximumFractionDigits: 0 })} kWh</span>
                        </div>
                    </div>
                </Row>
                {hasWarmWaterDevices &&
                    <Row className={`my-2 mb-2 d-flex flex-nowrap gap-4 mr-0 ml-0`}>
                        <div className={'d-flex flex-wrap align-items-center justify-content-center gap-2'} style={{ width: '30%' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="44" width="44" style={{ transform: 'scale(1.15)' }}>
                                <path fill="#cc2b2b"
                                      d="M23.95 37.6q.8 0 1.225-.275.425-.275.425-.825 0-.55-.425-.85-.425-.3-1.275-.3-2.1 0-4.275-1.325Q17.45 32.7 16.85 29.35q-.1-.45-.45-.725-.35-.275-.75-.275-.55 0-.85.425-.3.425-.2.875.75 4.2 3.55 6.075t5.8 1.875ZM24 44q-6.85 0-11.425-4.7Q8 34.6 8 27.6q0-5 3.975-10.875T24 4q8.05 6.85 12.025 12.725Q40 22.6 40 27.6q0 7-4.575 11.7Q30.85 44 24 44Zm0-3q5.6 0 9.3-3.825Q37 33.35 37 27.6q0-3.95-3.325-8.975Q30.35 13.6 24 8q-6.35 5.6-9.675 10.625Q11 23.65 11 27.6q0 5.75 3.7 9.575Q18.4 41 24 41Zm0-17Z"/>
                            </svg>
                            <p className={'font-medium-1 mb-0 w-100 text-center'}>Warmwasser- energie</p>
                        </div>
                        <div className="w-100">
                            <div style={{
                                background: colors.danger.main,
                                height: '32px',
                                minWidth: '150px',
                                width: calculateWidth(sumWarmWater, sumPreviousWarmWater, sumWarmWaterPercentage, 'current'),
                                borderTopRightRadius: '.75rem',
                                borderBottomRightRadius: '.75rem'
                            }} className={'mb-1'}>
                <span style={{ lineHeight: '32px' }} className={'text-white font-weight-bold font-medium-1 ml-1'}>
                  {sumWarmWater.toLocaleString('de-DE', { maximumFractionDigits: 0 })} kWh ({sumWarmWaterPercentage < 0 ? '' : '+'}{sumWarmWaterPercentage} %)
                </span>
                            </div>
                            <div style={{
                                background: colors.danger.light,
                                height: '32px',
                                minWidth: '150px',
                                width: calculateWidth(sumWarmWater, sumPreviousWarmWater, sumWarmWaterPercentage, 'previous'),
                                borderTopRightRadius: '.75rem',
                                borderBottomRightRadius: '.75rem'
                            }}>
                                <span style={{ lineHeight: '32px' }} className={'text-white font-weight-bold font-medium-1 ml-1'}>{sumPreviousWarmWater.toLocaleString('de-DE',
                                    { maximumFractionDigits: 0 })} kWh</span>
                            </div>
                        </div>
                    </Row>
                }
            </div>
        </>
    )
}
export default HeatingEnergyWidget