// ** Auth Endpoints
export default {
  loginEndpoint: `${process.env.REACT_APP_ASSETENERGY_APP_API}/auth/login`,
  registerEndpoint: '/jwt/register',
  refreshEndpoint: `${process.env.REACT_APP_ASSETENERGY_APP_API}/auth/refresh`,
  logoutEndpoint: '/jwt/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  apiTokenKeyName: 'access_token',
  apiTokenExpiresKeyName: 'expires',
  apiRefreshTokenKeyName: 'refresh_token',

  storageTokenKeyName: 'auth_token',
  storageTokenExpiresKeyName: 'auth_expires_at',
  storageTokenLifetimeKeyName: 'auth_expires',
  storageRefreshTokenKeyName: 'refresh_token'
}
