import { useContext, useEffect, useState } from 'react'
import { Row } from 'reactstrap'
import { useSelector } from 'react-redux'
import { ThemeColors } from '../../../../utility/context/ThemeColors'
import { Check } from 'react-feather'
import moment from 'moment'
import { hasWaterData, hasWWaterData } from '../../../../utility/DeviceData'

const LeakageWidget = (props) => {
    const { colors } = useContext(ThemeColors)
    const { usage, properties } = useSelector(state => state[props.store])
    const [waterDeviceCount, setWaterDeviceCount] = useState(0)
    const [date, setDate] = useState(moment().set('date', 1))

    useEffect(() => {
        if (usage && (Object.keys(usage.water_data).length > 0 || Object.keys(usage.wwater_data).length > 0)) {
            const waterDeviceCount = properties.reduce((prev, curr) => prev + curr.devices.filter((device) => device.type === 'Water' || device.type === 'WWater').length, 0)
            setWaterDeviceCount(waterDeviceCount)

            const deviceData = []
            if (hasWaterData(usage)) {
                const latestWaterDate = Object.keys(usage.water_data).slice(-2).shift()
                deviceData.push(latestWaterDate)
            }

            if (hasWWaterData(usage)) {
                const latestWWaterDate = Object.keys(usage.wwater_data).slice(-2).shift()
                deviceData.push(latestWWaterDate)
            }

            setDate(moment(new Date(Math.max(...deviceData.map(e => new Date(e))))))
        }
    }, [])

    const Checkmark = ({count, text}) => (
        <div className={'px-1 py-50 d-flex align-items-center gap-4 w-100'}>
            <div style={{borderRadius: '100px', background: colors.success.main, width: '30px', height: '30px'}} className={'d-flex justify-content-center align-items-center'}>
                <Check size={20} color={'#fff'}></Check>
            </div>
            <p className={'font-medium-2 m-0 flex-fill'}>
                <h5 className={'font-large-1 mr-1 mb-0 d-inline-block text-right'} style={{width: waterDeviceCount > 99 ? '50px' : '40px'}}>{count}</h5>
                <span className={'text-nowrap'}>{text}</span>
            </p>
        </div>
    )

    return (
        <>
            <h4 className={'mb-0'}>Automatische Leckageerkennung</h4>
            <span className={'mb-50'}>Zuletzt geprüft am {date.format('DD.MM.YYYY')}</span>
            <Row className={'widget-body water-widget align-items-center'}>
                <div className={'d-flex flex-wrap mb-50'}>
                    <Checkmark count={waterDeviceCount} text='Leitungen geprüft' />
                    <Checkmark count={usage?.leakage?.count || 0} text='Leckagen erkannt' />
                </div>
            </Row>
        </>
    )

}

export default LeakageWidget