// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";

import user from "@src/redux/reducers/user";
import billingReducer from '@src/views/apps/billing/store/slices/billing.slice'
import tipster from "@src/views/apps/tipster/store/reducer";
import onboardingReducer from '@src/views/apps/onboarding/store/slices/onboarding.slice'
import appointmentReducer from '@src/views/apps/appointment/store/slices/appointment.slice'
import content from "@src/views/pages/content/store/reducer";
import documentReducer from "@src/views/apps/document/store/slices/document.slice";
import propertyReducer from "@src/views/apps/property/store/slices/property.slice";
import apartmentReducer from "@src/views/apps/apartment/store/slices/apartment.slice";
import deviceReducer from "@src/views/apps/device/store/slices/device.slice";
import ownerReducer from "@src/views/apps/owner/store/slices/owner.slice";
import tenantReducer from "@src/views/apps/tenant/store/slices/tenant.slice";
import defaultDashboard from "@src/views/dashboard/default/store/reducer";
import apartmentDashboard from "@src/views/dashboard/apartment/store/reducer";
import propertyDashboard from "@src/views/dashboard/property/store/reducer";

const appReducer = combineReducers({
  auth,
  user,
  navbar,
  layout,
  billing: billingReducer,
  owner: ownerReducer,
  tenant: tenantReducer,
  tipster,
  appointment: appointmentReducer,
  content,
  documents: documentReducer,
  property: propertyReducer,
  apartment: apartmentReducer,
  onboarding: onboardingReducer,
  device: deviceReducer,
  defaultDashboard,
  apartmentDashboard,
  propertyDashboard,
})

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_ALL') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default rootReducer
