import moment from 'moment/moment'
import * as intl from 'react-intl/lib/formatters/message'
import { useSelector } from 'react-redux'

const isStartOfMonth = (date) => {
    return moment(date).format('DD-MM-YYYY') === moment(date).startOf('month').format('DD-MM-YYYY')
}

const isEndOfMonth = (date) => {
    return moment(date).format('DD-MM-YYYY') === moment(date).endOf('month').format('DD-MM-YYYY')
}

const filter = (item, options) => {
    if (!(isStartOfMonth(item.report_date) || isEndOfMonth(item.report_date))) {
        return false
    }

    if (options.begin && !moment(item.report_date).isSameOrAfter(moment(options.begin))) {
        return false
    }

    if (options.end && !moment(item.report_date).isSameOrBefore(moment(options.end))) {
        return false
    }

    // noinspection RedundantIfStatementJS
    if (options.unit && item.unit !== options.unit) {
        return false
    }

    return true
}

// Return value array
export const filterValues = (devices, options = {}) => {
    // Hide devices, that are not used in any apartment
    devices = devices.filter((device) => device.apartment_id !== null)

    if (options.type) {
        devices = devices.filter((device) => options.type.includes(device.type))
    }

    return devices.map((device) => device.data.filter((item) => {
        return filter(item, options)
    }))
}

// Return device array with filtered values
export const filterDevices = (devices, options) => {
    // Hide devices, that are not used in any apartment and if type array is given, filter by type
    return devices.filter(({ apartment_id, type }) => (apartment_id !== null && (!options.type || options.type.includes(type)))).
        map(({ data, ...device }) => ({ ...device, data: data ? data.filter(item => filter(item, options)) : [] }))
}


export const monthlyValueDifference = (dataset, convert = true) => {
    if (!Array.isArray(dataset) || dataset.length === 0) {
        return []
    }

    const monthlyValues = []
    dataset.forEach((data) => {
        const current_value = convert && data.converted_value ? data.converted_value : data.value

        const last_objects = dataset.filter((item) => moment(item.report_date).format('DDMMYYYY') === moment(data.report_date).subtract('1', 'month').format('DDMMYYYY'))
        let last_object = data
        if (last_objects.length > 0) {
            last_object = last_objects[0]
        }

        const last_value = convert && last_object.converted_value ? last_object.converted_value : last_object.value

        const difference = Math.abs(current_value - last_value)
        const date = moment(data.report_date).set('date', 1)

        monthlyValues.push([date.toDate().toDateString(), (difference)])
    })

    return monthlyValues
}

export const monthlyDeviceDifference = (devices, convert = true) => {
    if (!devices || devices.length === 0) {
        return []
    }

    const deviceValues = []
    devices.map((device) => {
        const consolidatedValues = monthlyValueDifference(device.data, convert)
        consolidatedValues.map((item) => {
            if (deviceValues[item[0]] === undefined) {
                deviceValues[item[0]] = 0
            }

            deviceValues[item[0]] += parseFloat(item[1])
        })
    })
    return deviceValues
}

export const exportDeviceData = (data) => {
    const rows = ['Datum;Wert;Einheit']
    data.forEach((item) => {
        rows.push(`${moment(item.report_date).format('DD.MM.YYYY')};${item.value?.toLocaleString()};${item.unit}`)
    })

    const blob = new Blob([rows.join('\n')], { type: 'text/csv;charset=utf-8;' })
    return URL.createObjectURL(blob)
}

export const exportDevicesData = (devices, intl) => {
    const rows = ['Zähler;Typ;Montageort;Datum;Wert;Einheit']
    devices.map((device) => {
        device.data.forEach((item) => {
            if (moment(item.report_date).format('DD') === '01') {
                rows.push(`${device.device_id};${intl.formatMessage({ id: device.type })};${device.apartment_id?.label};${moment(item.report_date).format('DD.MM.YYYY')};${item.value?.toLocaleString()};${item.unit ?? '-'}`)
            }
        })
    })

    const blob = new Blob([rows.join('\n')], { type: 'text/csv;charset=utf-8;' })
    return URL.createObjectURL(blob)
}

export const hasUsageData = (usage) => {
    return usage && Object.keys(usage).length > 0
}

export const hasWaterData = (usage) => {
    return usage?.water_data && Object.keys(usage.water_data).length > 0
}

export const hasWWaterData = (usage) => {
    return usage?.wwater_data && Object.keys(usage.wwater_data).length > 0
}

export const hasHeatingData = (usage) => {
    return usage?.heating_data && Object.keys(usage.heating_data).length > 0
}