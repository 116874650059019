import { useSelector } from 'react-redux'
import moment from 'moment/moment'
import { useContext, useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { ThemeColors } from '../../../../../utility/context/ThemeColors'
import { hasUsageData, hasWaterData, hasWWaterData } from '../../../../../utility/DeviceData'

const de = require('apexcharts/dist/locales/de.json')

const WaterConsumptionWidget = (props) => {
    const { colors } = useContext(ThemeColors)
    const usage = useSelector(state => state[props.store].usage)
    const [legend, setLegend] = useState([])
    const [waterValues, setWaterValues] = useState([])
    const [warmWaterValues, setWarmWaterValues] = useState([])
    const start = moment().subtract(1, 'year').startOf('month')

    const fillMonths = (data) => {
        const legendDate = moment(start).add(1, 'month').startOf('month')
        while (legendDate.isBefore(moment().startOf('month'))) {
            const index = data.findIndex((key) => {
                return moment(key[0]).isSame(legendDate, 'month')
            })
            if (index === -1) {
                const lastIndex = data.findIndex((key) => moment(key[0]).isSame(moment(legendDate).subtract(1, 'month'), 'month'))
                data.splice(lastIndex >= 0 ? lastIndex + 1 : 0, 0, [moment(legendDate).toDate().toDateString(), 0])
            }
            legendDate.add(1, 'month')
        }
    }

    useEffect(() => {
        if (hasUsageData(usage)) {
            let warmWaterValues = []
            if (hasWWaterData(usage)) {
                const warmWaterDifference = Object.entries(usage.wwater_data).filter(([key]) => moment(key).isAfter(start))
                fillMonths(warmWaterDifference)
                warmWaterValues = warmWaterDifference
                setWarmWaterValues(warmWaterValues)
            }

            let coldWaterValues = []
            if (hasWaterData(usage)) {
                const coldWaterDifference = Object.entries(usage.water_data).filter(([key]) => moment(key).isAfter(start))
                fillMonths(coldWaterDifference)
                coldWaterValues = coldWaterDifference
                setWaterValues(coldWaterValues)
            }

            setLegend(coldWaterValues.length > warmWaterValues.length ? coldWaterValues.map((item) => item[0]) : warmWaterValues.map((item) => item[0]))
        } else {
            // If no value is present apex charts breaks the x-axis and all labels are stacked on top of each other
            const legendDate = moment().subtract(1, 'year').startOf('month')
            while (legendDate.isBefore(moment().startOf('month'))) {
                legend.push(moment(legendDate).toDate().toDateString())
                legendDate.add(1, 'month')
            }

            const values = []
            legend.map((item) => {
                values.push([item, 0])
            })

            setWaterValues(values)
            setLegend(legend)
        }
    }, [usage])

    const options = {
        grid: {
            padding: {
                left: -20,
                right: 0,
                top: 30
            }
        },
        chart: {
            locales: [de],
            defaultLocale: 'de',
            toolbar: { show: false }
        },
        legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'left',
            offsetX: -35
        },
        colors: [colors.primary.main, colors.danger.main],
        xaxis: {
            type: 'category',
            categories: legend,
            labels: {
                formatter: (value) => {
                    if (typeof value === 'undefined') {
                        return
                    }

                    return moment(value).subtract(1, 'month').format('MMM')
                },
                style: {
                    colors: '#b9b9c3',
                    fontSize: '1rem'
                }
            }
        },
        dataLabels: {
            enabled: false
        },
        yaxis: {
            tickAmount: 5,
            labels: {
                offsetX: -25,
                style: {
                    colors: colors.secondary.main,
                    fontSize: '1rem'
                },
                formatter: (value) => parseFloat(value).toFixed(0)
            },
            title: {
                text: 'm³',
                rotate: 0,
                offsetX: 28,
                offsetY: -95,
                style: {
                    cssClass: 'apexcharts-yaxis-title',
                    fontSize: '1rem'
                }
            }
        },
        tooltip: {
            x: {
                format: 'MMMM yyyy'
            },
            y: {
                formatter: (val, data) => {
                    return `${parseFloat(val).toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 1 })} m³`
                }
            }
        },
        responsive: [
            {
                breakpoint: 768,
                options: {
                    yaxis: {
                        show: false
                    },
                    grid: {
                        padding: {
                            left: 0,
                            bottom: 10
                        }
                    }
                }
            }
        ]
    }

    const series = [
        {
            name: 'Kaltwasserverbrauch',
            data: waterValues.map(item => parseFloat(item[1]).toFixed(3))
        },
        {
            name: 'Warmwasserverbrauch',
            data: warmWaterValues.map(item => parseFloat(item[1]).toFixed(3))
        }
    ]

    return (
        <>
            <h4>Wasserverbrauch in der Jahresübersicht</h4>
            {legend &&
                <div className={'d-flex gap-4'}>
                    {<Chart options={options} series={series} type={'bar'} height={250} style={{ width: '100%' }}/>}
                </div>
            }
        </>
    )
}

export default WaterConsumptionWidget