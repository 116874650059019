import { useSelector } from 'react-redux'
import { ArrowDownCircle } from 'react-feather'
import { useContext, useEffect, useState } from 'react'
import { ThemeColors } from '../../../../utility/context/ThemeColors'
import { heatingEfficiencyTable, qmSumByProperties, sumHeatingWithForecastData } from '../../../../utility/Utils'
import { Badge } from 'reactstrap'
import moment from 'moment'
import dictionary from '../../../../utility/Dictionary'

const EfficiencyWidget = (props) => {
    const properties = useSelector(state => state[props.store].properties)
    const usage = useSelector(state => state[props.store].usage)
    const { colors } = useContext(ThemeColors)
    const [percentage, setPercentage] = useState(50)
    const [efficiency, setEfficiency] = useState('normal')
    const [qmValue, setQmValue] = useState(100)
    const [minValue, setMinValue] = useState(100)
    const [maxValue, setMaxValue] = useState(100)

    const ratingText = [
        <Badge style={{ background: '#79bf45', color: '#fff', fontWeight: 'bold' }}>gering</Badge>,
        <Badge style={{ background: '#79bf45', color: '#fff', fontWeight: 'bold' }}>gering</Badge>,
        <Badge style={{ background: '#f0be02', color: '#fff', fontWeight: 'bold' }}>normal</Badge>,
        <Badge style={{ background: '#f77552', color: '#fff', fontWeight: 'bold' }}>hoch</Badge>
    ]

    useEffect(() => {
        if (usage && properties.length > 0 && properties[0].heating) {
            const begin = moment().subtract(1, 'year')

            const calculatedSum = sumHeatingWithForecastData(begin, moment(begin).add(1, 'year'), usage?.heating_data, properties[0])
            const calculatedQm = qmSumByProperties(properties)

            const energyPerQm = calculatedSum / calculatedQm
            const heating = properties[0].heating

            const minValue = heatingEfficiencyTable[heating][0]
            const maxValue = heatingEfficiencyTable[heating][heatingEfficiencyTable[heating].length - 1]
            const calculatedPercentage = 100 - (((energyPerQm - minValue) * 100) / (maxValue - minValue))

            setPercentage(Math.min(Math.max(calculatedPercentage, 0), 100))
            setQmValue(energyPerQm)
            setMinValue(minValue)
            setMaxValue(maxValue)

            const index = heatingEfficiencyTable[heating].findIndex(value => value > energyPerQm)
            setEfficiency(index > -1 ? ratingText[index] : ratingText[ratingText.length - 1])
        }
    }, [properties])

    return (
        <>
            <h4>Heizverbrauch {props.store.indexOf('property') > -1 && <span>der {dictionary.property}</span>} {props.store.indexOf('apartment') > -1 && <span>der {dictionary.apartment}</span>}</h4>
            <div className="widget-body">
                <p>{efficiency}</p>

                {minValue !== 100 && maxValue !== 100 &&
                    <div style={{ left: `calc(${percentage}% - ${percentage >= 80 ? 45 + 42 : (percentage > 10 ? 42 : 0)}px)`, marginBottom: '0.25rem', transition: '1s' }}
                         className={'position-relative'}>
                        <div className={`d-inline-flex flex-wrap flex-column align-items-${percentage <= 10 ? 'start' : percentage >= 80 ? 'end' : 'center'} gap-1`}>
                            <span>{qmValue.toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 1 })} kWh/m²</span>
                            <ArrowDownCircle style={{ color: colors.primary.main }} size={24}></ArrowDownCircle>
                        </div>
                    </div>
                }

                <div className={'full-width d-flex align-items-center text-center text-white font-weight-bold'} style={{
                    height: '32px',
                    backgroundImage: 'linear-gradient(90deg, rgba(249,100,100,1) 0%, rgba(240,191,0,1) 50%, rgba(88,191,88,1) 100%)',
                    borderRadius: '15px'
                }}>
                    <div style={{ width: '33.3%', borderRight: '1px solid #ddd' }}>
                        hoch
                    </div>
                    <div style={{ width: '33.3%', borderRight: '1px solid #ddd' }}>
                        normal
                    </div>
                    <div style={{ width: '33.3%' }}>
                        gering
                    </div>
                </div>
                <div className={'d-flex full-width justify-content-between'} style={{ marginTop: '0.25rem' }}>
                    <span>{maxValue} kWh/m²</span>
                    <span>{minValue} kWh/m²</span>
                </div>
            </div>
        </>
    )
}

export default EfficiencyWidget