import { useSelector } from 'react-redux'
import { useContext, useEffect, useState } from 'react'
import { ThemeColors } from '../../../../utility/context/ThemeColors'
import Chart from 'react-apexcharts'
import { energyForecast } from '../../../../utility/Utils'
import moment from 'moment'
import { hasHeatingData } from '../../../../utility/DeviceData'

const de = require('apexcharts/dist/locales/de.json')

const CostForecastWidget = (props) => {
    const properties = useSelector(state => state[props.store]?.properties)
    const usage = useSelector(state => state[props.store]?.usage)
    const devices = useSelector(state => state[props.store]?.devices)
    const { colors } = useContext(ThemeColors)
    const [currentYearValues, setCurrentYearValues] = useState([])
    const [forecastValues, setForecastValues] = useState([])
    const [range, setRange] = useState([])

    useEffect(() => {
        const property = properties[0]
        if (property && devices && hasHeatingData(usage)) {
            const currentChartValues = [], forecastChartValues = []

            const result = energyForecast(usage.heating_data)
            const energyConsumption = usage.heating_data
            Object.entries(result).reverse().forEach(([key, value]) => {
                forecastChartValues.push([moment(key).toDate().toDateString(), value.toFixed(0)])
            })
            const lastMonthOfPreviousYear = moment().startOf('year').subtract(1, 'month').toDate().toDateString()
            const lastMonthOfPreviousYearValue = energyConsumption[lastMonthOfPreviousYear] ? energyConsumption[lastMonthOfPreviousYear].toFixed(0) : 0

            const currentMonth = moment().startOf('month').toDate().toDateString()

            currentChartValues.push([lastMonthOfPreviousYear, lastMonthOfPreviousYearValue])

            Object.entries(energyConsumption).forEach(([key, value]) => {
                if (moment(key).format('YYYY') === moment().format('YYYY') && moment(key).isSameOrBefore(moment())) {
                    if (moment(key).toDate().toDateString() === currentMonth && value !== 0) {
                        forecastChartValues.filter((item) => item[0] === currentMonth)[0][1] = value.toFixed(0) // update the forecast value with the current value when given
                    } else if (moment(key).toDate().toDateString() !== currentMonth) {
                        currentChartValues.push([moment(key).toDate().toDateString(), value.toFixed(0)])
                    }
                }
            })

            if (currentChartValues.length !== 0) {
                forecastChartValues.push(currentChartValues[currentChartValues.length - 1])
            }

            setForecastValues(forecastChartValues)
            setCurrentYearValues(currentChartValues)
        }
    }, [properties, usage, devices])


    useEffect(() => {
        const range = []

        const fromDate = moment()
        const endDate = moment().add(1, 'year')

        while (fromDate < endDate) {
            range.push(moment(fromDate).set('date', 1).toDate().toDateString())
            fromDate.add(1, 'month')
        }

        setRange(range)
    }, [])

    const options = {
        chart: {
            locales: [de],
            defaultLocale: 'de',
            toolbar: {
                show: false
            },
            parentHeightOffset: 0
        },
        legend: {
            show: false,
            position: 'top',
            horizontalAlign: 'left',
            offsetY: 0
        },
        markers: {
            size: 0,
            style: 'hollow'
        },
        xaxis: {
            type: 'datetime',
            categories: range,
            labels: {
                format: 'MMM',
                datetimeUTC: false,
                showDuplicates: true,
                style: {
                    colors: colors.secondary.main,
                    fontSize: '1rem'
                }
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 2,
            dashArray: [0, 7, 0],
            curve: 'smooth'
        },
        yaxis: {
            tickAmount: 5,
            labels: {
                offsetX: -35,
                style: {
                    colors: colors.secondary.main,
                    fontSize: '1rem'
                },
                formatter: (value) => {
                    if (!value) {
                        return 0
                    }

                    return `${value.toLocaleString('de-DE', { maximumFractionDigits: 0 })} kWh`
                }
            },
            title: {
                text: 'KWh',
                rotate: 0,
                offsetX: 30,
                offsetY: -110,
                style: {
                    cssClass: 'apexcharts-yaxis-title',
                    fontSize: '1rem'
                }
            }
        },
        grid: {
            padding: {
                left: -10,
                right: 20,
                top: 10,
                bottom: 0
            }
        },
        tooltip: {
            x: {
                format: 'MMMM'
            }
        }
    }
    const series = [
        {
            name: 'Aktuell',
            data: currentYearValues,
            type: 'line',
            color: colors.primary.main
        },
        {
            name: 'Prognose',
            data: forecastValues,
            type: 'line',
            color: colors.primary.main
        }
    ]

    return (
        <>
            <h4>Prognose Energieverbrauch</h4>
            <div className={'d-flex gap-4 widget cost-forecast'}>
                <Chart options={options} series={series} height={250} style={{ width: '100%' }}/>
            </div>
        </>
    )
}

export default CostForecastWidget