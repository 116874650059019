import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import dictionary from '../../../../utility/Dictionary'

const WelcomeWidget = () => {
    const user = useSelector(state => state.auth)
    const [description, setDescription] = useState('')

    useEffect(() => {
        if (user) {
            switch (user.role) {
                case 'admin': {
                    setDescription(`Hier können Sie Ihre Energiebilanz Ihrer ${dictionary.properties} einsehen, Verträge verwalten und Benachrichtigungen einstellen.`)
                    break
                }
                case 'property_administrator': {
                    setDescription(`Hier können Sie Ihre Energiebilanz Ihrer ${dictionary.properties} einsehen, Verträge verwalten und Benachrichtigungen einstellen.`)
                    break
                }
                case 'property_owner': {
                    setDescription(`Hier können Sie Ihre Energiebilanz Ihrer ${dictionary.properties} einsehen, Verträge verwalten und Benachrichtigungen einstellen.`)
                    break
                }
                case 'property_tenant': {
                    setDescription('Hier können Sie Ihre Energiebilanz und Abrechnungen einsehen.')
                    break
                }
            }
        }
    }, [user])

    return user ? (
        <>
            <h4>Guten Tag {user.me.last_name}!</h4>
            <hr/>
            <span>
                {description}
            </span>
        </>
    ) : null
}

export default WelcomeWidget