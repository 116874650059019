import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { qmSumByProperties } from '../../../../utility/Utils'
import { hasUsageData } from '../../../../utility/DeviceData'

const LastMonthWidget = (props) => {
    const usage = useSelector(state => state[props.store].usage)
    const properties = useSelector(state => state[props.store].properties)
    const [sum, setSum] = useState(0)
    const [count, setCount] = useState(1)
    const [qm, setQm] = useState(0)

    useEffect(() => {
        if (!hasUsageData(usage)) {
            return
        }

        const begin = moment().subtract('1', 'year').set('date', 1)
        const lastYearValue = Object.entries(usage.heating_data).filter(([key]) => moment(key).isAfter(begin) && moment(key).isBefore(moment()))
        const sum = lastYearValue.reduce((prev, curr) => prev + curr[1], 0)

        setSum(sum)
        setCount(lastYearValue.length || 0)
    }, [usage])

    useEffect(() => {
        setQm(qmSumByProperties(properties))
    }, [properties])

    return (
        <>
            <h4>Übersicht der letzten Monate</h4>
            <div className={'d-flex justify-content-around'}>
                <div className={'d-flex flex-column gap-2 p-1'}>
                    <h5 className={'text-center'}>
                        {qm > 0 && sum > 0 &&
                            <>
                                <span className={'font-large-1'}>{(sum / qm).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span> kWh /
                                m²
                            </>
                        }
                    </h5>
                    <p className={'text-center font-medium-1'}>Durchschnittlich<br/> je Quadratmeter</p>
                </div>
                <div style={{ borderRight: '1px solid #ddd' }}></div>
                <div className={'d-flex flex-column gap-2 p-1'}>
                    <h5 className={'text-center'}>
                        {sum && sum >= 1000 &&
                            <>
                                <span className={'font-large-1'}>
                                    {(sum / 1000).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </span> MWh
                            </>
                        }
                        {sum && sum < 1000 &&
                            <>
                                <span className={'font-large-1'}>
                                    {sum.toFixed(2)}
                                </span> kWh
                            </>
                        }
                    </h5>
                    <p className={'text-center font-medium-1'}>Jahres-<br/>gesamtverbrauch</p>
                </div>
            </div>
        </>
    )
}

export default LastMonthWidget