const econ = {
    property: 'Erzeuger',
    properties: 'Erzeuger',
    apartment: 'Übergabestation',
    apartments: 'Übergabestationen',
    tenant: 'Kunde',
    tenants: 'Kunden',
    owner: 'Mitarbeiter',
    owners: 'Mitarbeiter'
}

const asset = {
    property: 'Immobilie',
    properties: 'Immobilien',
    apartment: 'Wohnung',
    apartments: 'Wohnungen',
    tenant: 'Mieter',
    tenants: 'Mieter',
    owner: 'Eigentümer',
    owners: 'Eigentümer'
}

let dictionary = asset
if (document.location.host === 'econ.assetapp.de' || document.cookie.match(/^(.*;)?\s*ECON\s*=\s*[^;]+(.*)?$/)) {
    dictionary = econ
}

export default dictionary