import mock from '../mock'
import { paginateArray, sortCompare } from '../utils'

const data = {
  properties: [
    {
      id: 1,
      issuedDate: '20 Dezember 2021',
      client: {
        address: '7777 Mendez Plains',
        company: 'Hall-Robbins PLC',
        companyEmail: 'don85@johnson.com',
        country: 'USA',
        contact: '(616) 865-4180',
        name: 'Die Mieterin'
      },
      avatar: require('@src/assets/images/avatars/1-small.png').default,
      service: 'Heizungsabrechnung',
      total: 3428,
      avatar: '',
      propertyStatus: 'Paid',
      balance: '€724',
      dueDate: '21 Dezember 2021'
    }
  ]
}

// ------------------------------------------------
// GET: Return Property List
// ------------------------------------------------
mock.onGet('/apps/property/properties').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, status = null } = config
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.properties
    .filter(
      property =>
        /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
        (property.client.companyEmail.toLowerCase().includes(queryLowered) ||
          property.client.name.toLowerCase().includes(queryLowered)) &&
        property.propertyStatus.toLowerCase() === (status.toLowerCase() || property.propertyStatus.toLowerCase())
    )
    .sort(sortCompare('id'))
    .reverse()
  /* eslint-enable  */

  return [
    200,
    {
      allData: data.properies,
      properties: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// ------------------------------------------------
// GET: Return Single Property
// ------------------------------------------------
mock.onGet(/\/api\/property\/properties\/\d+/).reply(config => {
  // // Get event id from URL
  const propertyId = Number(config.url.substring(config.url.lastIndexOf('/') + 1))

  const propertyIndex = data.properties.findIndex(e => e.id === propertyId)
  const responseData = {
    property: data.properties[propertyIndex],
    paymentDetails: {
      totalDue: '$12,110.55',
      bankName: 'American Bank',
      country: 'United States',
      iban: 'ETD95476213874685',
      swiftCode: 'BR91905'
    }
  }
  return [200, responseData]
})

// ------------------------------------------------
// DELETE: Deletes Property
// ------------------------------------------------
mock.onDelete('/apps/property/delete').reply(config => {
  // Get property id from URL
  let propertyId = config.id

  // Convert Id to number
  propertyId = Number(propertyId)

  const propertyIndex = data.properties.findIndex(t => t.id === propertyId)
  data.property.splice(propertyIndex, 1)

  return [200]
})

// ------------------------------------------------
// GET: Return Clients
// ------------------------------------------------
mock.onGet('/api/property/clients').reply(() => {
  const clients = data.properties.map(property => property.client)
  return [200, clients.slice(0, 5)]
})
