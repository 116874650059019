// ** Initial State
const initialState = {
    allData: [],
    invitationLink: false,
    activeContacts: 0,
    data: [],
    total: 1,
    params: {},
    selectedTipster: null
}

const user = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_TIPSTER_TIPS':
            return { ...state, allData: action.data, invitationLink: action.invitationlink, activeContacts: action.activeContacts }
        case 'GET_TIPSTER_TIP':
            return { ...state, selectedTipster: action.selectedTipster }
        case 'GET_INVITATION':
            return { ...state, selectedTipster: action.selectedTipster }
        case 'ADD_INVITATION':
            return { ...state }
        case 'EDIT_INVITATION':
            return { ...state }
        default:
            return { ...state }
    }
}
export default user