/**
 * Function to sort array of objects by property
 *
 * @param {string} property      property of object to sort by
 * @param {boolean} ascending      define sorting direction
 */
export function dynamicSortObjectArray(property, ascending = true) {
    const sortOrder = ascending ? 1 : -1

    return function(a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
        return result * sortOrder
    }
}