import { useSelector } from 'react-redux'
import { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { percentageDifference } from '../../../../utility/Utils'
import { Badge, Row } from 'reactstrap'
import './widgets.scss'
import { ThemeColors } from '../../../../utility/context/ThemeColors'
import { hasHeatingData, hasWaterData, hasWWaterData } from '../../../../utility/DeviceData'

const LastMonthWaterWidget = (props) => {
    const { colors } = useContext(ThemeColors)
    const { usage } = useSelector(state => state[props.store])
    const [sumHeat, setSumHeat] = useState(0)
    const [sumColdWater, setSumColdWater] = useState(0)
    const [sumWarmWater, setSumWarmWater] = useState(0)

    const [heatPercentage, setHeatPercentage] = useState(0)
    const [coldWaterPercentage, setColdWaterPercentage] = useState(0)
    const [warmWaterPercentage, setWarmWaterPercentage] = useState(0)

    useEffect(() => {
        if (Object.keys(usage).length === 0) {
            return
        }

        const date = moment().set('date', 1)
        const previousDate = moment().subtract(1, 'month').set('date', 1)

        if (hasHeatingData(usage)) {
            const heatDifference = usage.heating_data
            const previousHeat = heatDifference[previousDate.toDate().toDateString()] ?? 0
            const heat = heatDifference[date.toDate().toDateString()]

            if (typeof heat === 'number') {
                setSumHeat(heat)
                setHeatPercentage(percentageDifference(heat, previousHeat))
            }
        }

        if (hasWWaterData(usage)) {
            const warmWaterDifference = usage.wwater_data
            const previousWarmWater = warmWaterDifference[previousDate.toDate().toDateString()] ?? 0
            const warmWater = warmWaterDifference[date.toDate().toDateString()]
            if (typeof warmWater === 'number') {
                setSumWarmWater(warmWater)
                setWarmWaterPercentage(percentageDifference(warmWater, previousWarmWater))
            }
        }

        if (hasWaterData(usage)) {
            const coldWaterDifference = usage.water_data
            const previousColdWater = coldWaterDifference[previousDate.toDate().toDateString()] ?? 0
            const coldWater = coldWaterDifference[date.toDate().toDateString()]
            if (typeof coldWater === 'number') {
                setSumColdWater(coldWater)
                setColdWaterPercentage(percentageDifference(coldWater, previousColdWater))
            }
        }
    }, [usage])

    return (
        <>
            <h4>Verbrauch des letzten Monats</h4>
            <Row className={'widget-body water-widget align-items-center'}>
                {hasWaterData(usage) &&
                    <>
                        <div
                            className={`col mb-1 mb-md-0 water-widget--item d-flex flex-column align-items-center py-1`}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                                <path
                                    d="M23.95 37.6q.8 0 1.225-.275.425-.275.425-.825 0-.55-.425-.85-.425-.3-1.275-.3-2.1 0-4.275-1.325Q17.45 32.7 16.85 29.35q-.1-.45-.45-.725-.35-.275-.75-.275-.55 0-.85.425-.3.425-.2.875.75 4.2 3.55 6.075t5.8 1.875ZM24 44q-6.85 0-11.425-4.7Q8 34.6 8 27.6q0-5 3.975-10.875T24 4q8.05 6.85 12.025 12.725Q40 22.6 40 27.6q0 7-4.575 11.7Q30.85 44 24 44Zm0-3q5.6 0 9.3-3.825Q37 33.35 37 27.6q0-3.95-3.325-8.975Q30.35 13.6 24 8q-6.35 5.6-9.675 10.625Q11 23.65 11 27.6q0 5.75 3.7 9.575Q18.4 41 24 41Zm0-17Z"/>
                            </svg>
                            <h5 className={'mb-0'}>
                                    <span className={'font-large-1'}>
                                        {sumColdWater.toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
                                    </span>
                                <b>&nbsp;m³</b>
                            </h5>
                            <p className={'font-medium-1 mb-0'}>Kaltwasser</p>
                            {!!(coldWaterPercentage && coldWaterPercentage !== 0) &&
                                <Badge style={{
                                    borderRadius: '1rem',
                                    color: '#fff',
                                    fontWeight: 'bold',
                                    backgroundColor: coldWaterPercentage <= 0 ? colors.success.main : colors.danger.main
                                }}>{coldWaterPercentage < 0 ? '' : '+'}{coldWaterPercentage} %</Badge>
                            }
                        </div>
                    </>
                }
                {hasWWaterData(usage) &&
                    <div
                        className={`col mb-1 mb-md-0 water-widget--item d-flex flex-column align-items-center py-1`}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                            <path fill="#cc2b2b"
                                  d="M23.95 37.6q.8 0 1.225-.275.425-.275.425-.825 0-.55-.425-.85-.425-.3-1.275-.3-2.1 0-4.275-1.325Q17.45 32.7 16.85 29.35q-.1-.45-.45-.725-.35-.275-.75-.275-.55 0-.85.425-.3.425-.2.875.75 4.2 3.55 6.075t5.8 1.875ZM24 44q-6.85 0-11.425-4.7Q8 34.6 8 27.6q0-5 3.975-10.875T24 4q8.05 6.85 12.025 12.725Q40 22.6 40 27.6q0 7-4.575 11.7Q30.85 44 24 44Zm0-3q5.6 0 9.3-3.825Q37 33.35 37 27.6q0-3.95-3.325-8.975Q30.35 13.6 24 8q-6.35 5.6-9.675 10.625Q11 23.65 11 27.6q0 5.75 3.7 9.575Q18.4 41 24 41Zm0-17Z"/>
                        </svg>
                        <h5 className={'mb-0'}>
                                <span className={'font-large-1'}>
                                    {sumWarmWater.toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
                                </span>
                            <b>&nbsp;m³</b>
                        </h5>
                        <p className={'font-medium-1 mb-0'}>Warmwasser</p>
                        {!!(warmWaterPercentage && warmWaterPercentage !== 0) &&
                            <Badge style={{
                                borderRadius: '1rem',
                                color: '#fff',
                                fontWeight: 'bold',
                                backgroundColor: warmWaterPercentage <= 0 ? colors.success.main : colors.danger.main
                            }}>{warmWaterPercentage < 0 ? '' : '+'}{warmWaterPercentage} %</Badge>
                        }
                    </div>
                }
                {hasHeatingData(usage) &&
                    <>
                        <div
                            className={`col mb-1 mb-md-0 water-widget--item d-flex flex-column align-items-center py-1`}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                                <path fill="orange"
                                      d="M11.65 42q-2.35 0-4-1.625T6 36.4V16h3v20.4q0 1.1.775 1.85t1.875.75q1.1 0 1.85-.75t.75-1.85V11.65q0-2.35 1.65-4t4-1.65q2.35 0 3.975 1.65t1.625 4V36.4q0 1.1.775 1.85t1.875.75q1.1 0 1.85-.75t.75-1.85V11.65q0-2.35 1.65-4t4-1.65q2.35 0 3.975 1.65t1.625 4V32h-3V11.65q0-1.1-.75-1.875Q37.5 9 36.4 9t-1.875.775q-.775.775-.775 1.875V36.4q0 2.35-1.625 3.975T28.15 42q-2.35 0-4-1.625T22.5 36.4V11.65q0-1.1-.75-1.875Q21 9 19.9 9t-1.875.775q-.775.775-.775 1.875V36.4q0 2.35-1.625 3.975T11.65 42Z"/>
                            </svg>
                            <h5 className={'mb-0'}>
                                <span className={'font-large-1'}>
                                    {sumHeat.toLocaleString('de-DE', { maximumFractionDigits: 0 })}
                                </span>
                                <b>&nbsp;kWh</b>
                            </h5>
                            <p className={'font-medium-1 mb-0'}>Heizenergie</p>
                            {!!(heatPercentage && heatPercentage !== 0) &&
                                <Badge style={{
                                    borderRadius: '1rem',
                                    color: '#fff',
                                    fontWeight: 'bold',
                                    backgroundColor: heatPercentage <= 0 ? colors.success.main : colors.danger.main
                                }}>{heatPercentage < 0 ? '' : '+'}{heatPercentage} %</Badge>
                            }
                        </div>
                    </>
                }
                {(!hasHeatingData(usage) && !hasWWaterData(usage) && !hasWaterData(usage)) &&
                    <p className={'p-1'}>Es sind keine Daten vorhanden</p>
                }
            </Row>
        </>
    )
}

export default LastMonthWaterWidget