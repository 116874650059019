// ** React Imports
import { createContext, useState } from 'react'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

// ** Core Language Data
import messagesEn from '@assets/data/locales/en.json'
import messagesDe from '@assets/data/locales/de.json'
import messagesFr from '@assets/data/locales/fr.json'
import messagesPt from '@assets/data/locales/pt.json'

// ** User Language Data
import userMessagesEn from '@src/assets/data/locales/en.json'
import userMessagesDe from '@src/assets/data/locales/de.json'
import userMessagesFr from '@src/assets/data/locales/fr.json'
import userMessagesPt from '@src/assets/data/locales/pt.json'
import dictionary from '../Dictionary'

// ** Menu msg obj
const menuMessages = {
    en: { ...messagesEn, ...userMessagesEn },
    de: { ...messagesDe, ...userMessagesDe, Properties: dictionary.properties, Owners: dictionary.owners, Tenants: dictionary.tenants, property_owner: dictionary.owner, property_tenant: dictionary.tenant },
    fr: { ...messagesFr, ...userMessagesFr },
    pt: { ...messagesPt, ...userMessagesPt }
}

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {
    // ** States
    const [locale, setLocale] = useState('de')
    const [messages, setMessages] = useState(menuMessages['de'])

    // ** Switches Language
    const switchLanguage = lang => {
        setLocale(lang)
        setMessages(menuMessages[lang])
    }

    return (
        <Context.Provider value={{ locale, switchLanguage }}>
            <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale="de">
                {children}
            </IntlProvider>
        </Context.Provider>
    )
}

export { IntlProviderWrapper, Context as IntlContext }