import { useSelector } from 'react-redux'
import { ArrowDownCircle } from 'react-feather'
import { useContext, useEffect, useState } from 'react'
import { ThemeColors } from '../../../../utility/context/ThemeColors'
import { emissionTable, heatingSystemsEmissions, qmSumByProperties, sumHeatingWithForecastData } from '../../../../utility/Utils'
import { Badge } from 'reactstrap'
import moment from 'moment'

const OwnerShareWidget = (props) => {
    const properties = useSelector(state => state[props.store].properties)
    const usage = useSelector(state => state[props.store].usage)
    const { colors } = useContext(ThemeColors)
    const [percentage, setPercentage] = useState(50)
    const [color, setColor] = useState('#fff')
    const [qmValue, setQmValue] = useState(100)


    useEffect(() => {
        if (usage && properties.length > 0 && properties[0].heating) {
            const begin = moment().subtract(1, 'year')
            const calculatedSum = sumHeatingWithForecastData(begin, moment(begin).add(1, 'year'), usage?.heating_data, properties[0])
            const calculatedQm = qmSumByProperties(properties)

            const annualConsumption = heatingSystemsEmissions[properties[0].heating] * calculatedSum
            const emissionPerSquareMeter = annualConsumption / calculatedQm
            setQmValue(emissionPerSquareMeter)

            const ownerShare = Object.entries(emissionTable).filter(([key]) => {
                return key <= emissionPerSquareMeter
            })

            if (ownerShare.length > 0) {
                const lastElement = ownerShare.pop()[1]
                setPercentage(lastElement[0])
                setColor(lastElement[1])
            }
        }
    }, [properties])

    const GeneratePercentage = () => {
        return Object.values(emissionTable).map((item, index) =>
            <div key={index} style={{ width: '10%' }}>
                {item[0]}%
            </div>
        )
    }

    return (
        <>
            <h4>Vermieter-Anteil an CO2 Steuer</h4>
            <div className="widget-body">
                <p className={'m-0 mb-2'}>
                    <Badge style={{ background: color, color: '#fff', fontWeight: 'bold' }}>{percentage}%</Badge>
                </p>

                <div style={{
                    left: `${percentage + 3}%`,
                    marginBottom: '0.25rem',
                    transition: '1s'
                }} className={'position-relative'}>
                    <div style={{ position: 'relative' }}
                         className={`d-inline-flex flex-wrap flex-column align-items-${percentage <= 10 ? 'start' : percentage >= 80 ? 'end' : 'center'} gap-1`}>
                        <span style={{ position: 'absolute', width: '150px', textAlign: `${percentage <= 10 ? 'left' : percentage >= 80 ? 'right' : 'center'}`, top: '-20px' }}>
                            {qmValue.toLocaleString('de-DE', {
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 1
                            })} kg CO2/m²/a</span>
                        <ArrowDownCircle style={{ color: colors.primary.main }} size={24}></ArrowDownCircle>
                    </div>
                </div>

                <div className={'full-width d-flex align-items-center text-center text-white font-weight-bold'} style={{
                    height: '40px',
                    backgroundImage: 'linear-gradient(90deg, rgba(88,191,88,1) 0%, rgba(240,191,0,1) 50%, rgba(249,100,100,1) 100%)',
                    borderRadius: '15px'
                }}>
                    <GeneratePercentage/>
                </div>
                <div className={'d-flex full-width justify-content-between'} style={{ marginTop: '0rem' }}>
                    <span>{'<'} 12 kg CO2/m²/a</span>
                    <span>{'>='} 52 kg CO2/m²/a</span>
                </div>
            </div>
        </>
    )
}

export default OwnerShareWidget