import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'
import {clearDataOnLogout, saveState} from "../../../utility/Utils"

export default class JwtService {
    // ** jwtConfig <= Will be used by this service
    jwtConfig = {...jwtDefaultConfig}

    // ** For Refreshing Token
    isAlreadyFetchingAccessToken = false

    // ** For Refreshing Token
    subscribers = []

    constructor(jwtOverrideConfig) {
        this.jwtConfig = {...this.jwtConfig, ...jwtOverrideConfig}

        // ** Request Interceptor
        axios.interceptors.request.use(
            config => {
                if (process.env.REACT_APP_ASSETENERGY_APP_API.indexOf(new URL(config.url).host) === -1) {
                    return config
                }

                const expiresAt = parseInt(localStorage.getItem(this.jwtConfig.storageTokenExpiresKeyName))
                const expiresIn = parseInt(localStorage.getItem(this.jwtConfig.storageTokenLifetimeKeyName))
                const now = +(new Date())

                if (expiresAt - now < expiresIn / 4 && !this.isAlreadyFetchingAccessToken) {
                    const token = this.getRefreshToken()
                    if (token !== null && token.length > 32) {
                        this.isAlreadyFetchingAccessToken = true
                        this.refreshToken().then(r => {
                            this.isAlreadyFetchingAccessToken = false

                            // ** Update accessToken in localStorage
                            this.setToken(r.data.data[this.jwtConfig.apiTokenKeyName])
                            this.setRefreshToken(r.data.data[this.jwtConfig.apiRefreshTokenKeyName], r.data.data[this.jwtConfig.apiTokenExpiresKeyName])

                            this.onAccessTokenFetched(r.data.data[this.jwtConfig.apiTokenKeyName])
                        })
                    }
                }

              // ** If token is present add it to request's Authorization Header
              const accessToken = this.getToken()
              if (accessToken) {
                  config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`.replaceAll('"', '')
              }
              return config
            },
            error => Promise.reject(error)
        )

        // ** Add request/response interceptor
        axios.interceptors.response.use(
            response => response,
            error => {
                // ** const { config, response: { status } } = error
                const {config, response} = error

                if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_ASSETENERGY_APP_API.indexOf(new URL(config.url).host) === -1) {
                    return Promise.reject(error)
                }

                const state = JSON.parse(localStorage.getItem('state'))
                const is_demo_user = state?.auth?.me.is_demo_user

                // Redirect to default route if session timeout
                // Response is undefined if directus doesn't send CORS HEADER (logged out or not available)
                // Demo user login uses non-session based token, so the token has no expiration timestamp
                console.log('LOGOUT', response, typeof response, this.getToken(), this.getExpirationTimestamp(), Date.now(), !is_demo_user)
                if (response.status === 401 || !this.getToken() || (this.getExpirationTimestamp() < Date.now() && !is_demo_user)) {
                    clearDataOnLogout()
                    window.location = '/'
                }

                return Promise.reject(error)
            }
        )
    }

    onAccessTokenFetched(accessToken) {
        this.subscribers = this.subscribers.filter(callback => callback(accessToken))
    }

    addSubscriber(callback) {
        this.subscribers.push(callback)
    }

    getToken() {
        return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
    }

    getExpirationTimestamp() {
        return localStorage.getItem(this.jwtConfig.storageTokenExpiresKeyName)
    }

    getRefreshToken() {
        return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
    }

    setToken(value) {
        localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
    }

    setRefreshToken(value, expires) {
        localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
        localStorage.setItem(this.jwtConfig.storageTokenExpiresKeyName, +(new Date()) + expires)
    }

    login(...args) {
        return axios.post(this.jwtConfig.loginEndpoint, ...args)
    }

    register(...args) {
        return axios.post(this.jwtConfig.registerEndpoint, ...args)
    }

    refreshToken() {
        return axios.post(this.jwtConfig.refreshEndpoint, {
            [this.jwtConfig.apiRefreshTokenKeyName]: this.getRefreshToken().replaceAll('"', '')
        })
    }
}
