import { useContext, useEffect, useState } from 'react'
import { floorToString } from '../../../../utility/Utils'
import moment from 'moment/moment'
import { Row } from 'reactstrap'
import { useSelector } from 'react-redux'
import { ThemeColors } from '../../../../utility/context/ThemeColors'

const DataSheetWidget = (props) => {
    const { colors } = useContext(ThemeColors)
    const apartment = useSelector(state => state[props.store]?.properties[0]?.apartments[0])
    const [serviceChargeCost, setServiceChargeCost] = useState(null)

    useEffect(() => {
        const apartmentMappings = apartment?.tenants
        if (!apartmentMappings || apartmentMappings.length === 0) {
            return
        }

        const serviceCharges = apartmentMappings[apartmentMappings.length - 1].service_charge_cost
        if (!serviceCharges || serviceCharges.length === 0) {
            return
        }

        const now = moment().subtract(1, 'month').startOf('month')
        const cost = serviceCharges[serviceCharges.length - 1]
        if (moment(cost.begin).isSameOrBefore(now)) {
            setServiceChargeCost(cost.heating_cost_deduction + cost.service_charge_deduction)
        }
    }, [])

    return (
        <>
            {apartment && <>
                <div className="d-flex justify-content-between">
                    <h4 className="font-weight-bold mb-0">{apartment.label}</h4>
                </div>
                <Row className={'widget-body align-items-center mt-2'}>
                    <div className={`col mb-1 d-flex flex-column align-items-center py-1`}>
                        <span className={'font-large-1 font-weight-bold'} style={{ color: colors.primary.main }}>
                            {apartment?.size_sqm.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            <span className={'font-small-4'}> m<sup>2</sup></span>
                        </span>
                        <p className={'font-medium-1 mt-0'}>Wohnfläche</p>
                    </div>
                    <div className={`col mb-1 d-flex flex-column align-items-center py-1`}>
                        <span className={'font-large-1 font-weight-bold'} style={{ color: colors.primary.main }}>{floorToString(apartment.floor)}</span>
                        <p className={'font-medium-1 mt-0'}>Etage</p>
                    </div>
                    <div className={`col mb-1 d-flex flex-column align-items-center py-1`}>
                       <span className={'font-large-1 d-flex flex-column align-items-center'}>
                           {serviceChargeCost &&
                               <>
                                   <span className={'font-large-1 font-weight-bold'} style={{ color: colors.primary.main }}>{serviceChargeCost}<span
                                       className={'font-small-4'}> €</span></span>
                                   <p className={'font-medium-1 mt-0 align-items-center'}>Mon. Vorauszahlung</p>
                               </>
                           }
                         </span>
                    </div>
                </Row>
            </>}
        </>
    )

}

export default DataSheetWidget