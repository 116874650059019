import moment from 'moment'

const initialState = {
    devices: [],
    properties: [],
    usage: [],
    begin: moment(),
    end: moment(),
    loaded: false
}

const propertyDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PROPERTY_DASHBOARD':
            return {
                ...state,
                devices: action.data.property.devices,
                properties: [action.data.property],
                usage: action.data.usage,
                begin: action.begin,
                end: action.end,
                loaded: true
            }
        case 'CLEAR_PROPERTY_DASHBOARD':
            return initialState
        default:
            return { ...state }
    }
}
export default propertyDashboardReducer