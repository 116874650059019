// ** Redux, Thunk & Root Reducer Imports
import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import rootReducer from '../reducers/rootReducer'
import { createStore, applyMiddleware, compose } from 'redux'
import { loadState, saveState } from '../../utility/Utils'
import throttle from 'lodash.throttle'
import * as Sentry from "@sentry/react";
// ** init middleware
const middleware = [thunk, createDebounce()]

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// ** Create store
const persistedState = loadState()

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(applyMiddleware(...middleware), sentryReduxEnhancer),
)
store.subscribe(
  throttle(() => {
    if (
      Object.keys(store.getState().onboarding).length > 0 &&
      Object.keys(store.getState().auth).length > 0
    ) {
      saveState({
        onboarding: store.getState().onboarding,
        auth: store.getState().auth,
      })
    }
  }, 1000),
)

// grant tipster modul access
store.subscribe(() => { 
  const auth = store.getState().auth
  if (auth && auth.me && auth.me.is_tipster) {
      auth.ability.push({
          action: 'manage',
          subject: 'tipster',
      })
      saveState({ auth })
    }
  }
)

export { store }
