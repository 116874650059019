const authReducer = (state = {}, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        ...action.data
      }
    case 'LOGOUT':
      const obj = { ...action }
      delete obj.type
      return { ...state, data: {}, ...obj }
    default:
      return state
  }
}

export default authReducer
