import Chart from 'react-apexcharts'
import { useContext, useEffect, useState } from 'react'
import { ThemeColors } from '../../../../utility/context/ThemeColors'
import { useSelector } from 'react-redux'
import { defaultHeatingPerSqm, qmSumByProperties, sumHeatingWithForecastData } from '../../../../utility/Utils'
import moment from 'moment/moment'
import { hasHeatingData } from '../../../../utility/DeviceData'

// DEMO: https://codesandbox.io/s/donut-charts-testing-forked-8fgsey?file=/src/App.tsx:1423-1433
const EnergyUsageWidget = (props) => {
    const { colors } = useContext(ThemeColors)
    const properties = useSelector(state => state[props.store].properties)
    const usage = useSelector(state => state[props.store].usage)
    const [yearUsageSum, setYearUsageSum] = useState(0)
    const [qm, setQm] = useState(0)
    const [options, setOptions] = useState()
    const [chartValues, setChartValues] = useState([])

    useEffect(() => {
        if (hasHeatingData(usage)) {
            setYearUsageSum(sumHeatingWithForecastData(moment().startOf('year'), moment().endOf('year'), usage.heating_data, properties[0]))
        }
    }, [usage])

    useEffect(() => {
        setQm(qmSumByProperties(properties))
    }, [properties])

    useEffect(() => {
        if (yearUsageSum && qm && properties) {
            const defaultHeatingPerSqmForProperty = properties.length > 1 ? (defaultHeatingPerSqm[properties[0].heating] ?? defaultHeatingPerSqm.default) : defaultHeatingPerSqm.default
            const defaultHeatingForLastMonth = defaultHeatingPerSqmForProperty * qm
            const absoluteDifference = Math.abs(defaultHeatingForLastMonth - yearUsageSum)
            const sum = yearUsageSum + absoluteDifference
            const percentageDifference = (((absoluteDifference / sum)) * 100).toFixed(0) * (defaultHeatingForLastMonth < yearUsageSum ? 1 : -1)

            setChartValues([yearUsageSum / sum, absoluteDifference / sum])
            setOptions(
                {
                    chart: {
                        toolbar: { show: false }
                    },
                    grid: {
                        padding: {
                            top: 20
                        }
                    },
                    legend: {
                        show: true,
                        position: 'top',
                        horizontalAlign: 'left',
                        offsetX: -35,
                        offsetY: 10
                    },
                    dataLabels: {
                        enabled: false
                    },

                    tooltip: {
                        y: {
                            formatter: (w) => {
                                return `${parseFloat(w * sum).toLocaleString('de-DE', { maximumFractionDigits: 0 })} kWh`
                            }
                        }
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        offsetY: -2
                                    },
                                    value: {
                                        offsetY: -2
                                    },
                                    total: {
                                        show: true,
                                        showAlways: true,
                                        maxWidth: 80,
                                        label: `${percentageDifference > 0 ? '+' : ''}${percentageDifference} %`,
                                        formatter: () => {
                                            return `${percentageDifference > 0 ? 'mehr' : 'weniger'} verbraucht`
                                        }
                                    }
                                }
                            }
                        }
                    },
                    colors: [colors.primary.main, percentageDifference < 0 ? colors.success.main : colors.danger.main],
                    labels: ['Ihr Energieverbrauch bis zum 31.12', 'Differenz zum Referenzobjekt']
                }
            )
        }
    }, [yearUsageSum, qm])

    return (
        <>
            <h4>Energieverbrauch im Vergleich</h4>
            <span>Im Vergleich zu Referenzobjekten (pro m²)</span>
            {options &&
                <div className={'w-100'}>
                    <div className={'position-relative'}>
                        <Chart options={options} series={chartValues} type={'donut'} height={284}
                               style={{ width: '100%' }}
                        />
                    </div>
                </div>
            }
            {!options &&
                <>
                    Keine Daten vorhanden
                </>
            }
        </>
    )
}

export default EnergyUsageWidget